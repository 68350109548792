import { showError, showErrors } from "./Toast";
import * as constants from "../resources/constants";

/**
 * Converts 24hr time to more readable 12hr strings
 * I.e 16:00:00 --> 4:00pm
 * @param {string} timeString: HH:MM:SS format time string
 * @returns {string} string in 12 hr format
 */
export default function convertTime(timeString) {
  const hour = parseInt(timeString.substring(0, 2), 10);
  const minutes = timeString.substring(3, 5);
  if (hour > 12) {
    return `${hour - 12}:${minutes}pm`;
  }

  return `${hour}:${minutes}am`;
}

export function fixObjForDatabase(obj) {
  const newObj = { ...obj };
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(newObj)) {
    // eslint-disable-next-line no-param-reassign
    if (
      value === undefined ||
      value === "" ||
      ((typeof value === "string" || value instanceof String) &&
        value.trim() === "")
    )
      newObj[key] = null;
  }
  return newObj;
}

export function convertToDateObject(UtcString) {
  if (
    UtcString &&
    (typeof UtcString === "string" || UtcString instanceof String)
  )
    return new Date(UtcString);
  return UtcString;
}

export async function handleError(e) {
  if (e.res) {
    const body = await e.res.json();
    if (body.errors) {
      showErrors(body);
    } else {
      showError(body.message);
    }
  } else {
    showError(e.name);
  }
  return null;
}

export function getBillingTooltip(billingOption) {
  if (!billingOption || !billingOption.idRef)
    return constants.readableBilling.unavailable;
  switch (billingOption.idRef) {
    case constants.NHSD.billing.bulkOnly:
      return constants.readableBilling.bulkBillingOnly;
    case constants.NHSD.billing.byDonation:
      return constants.readableBilling.byDonation;
    case constants.NHSD.billing.coPayment:
      return constants.readableBilling.coPayment;
    case constants.NHSD.billing.feesAndBulkBilling:
      return constants.readableBilling.feesAndBulkBilling;
    case constants.NHSD.billing.feesApply:
      return constants.readableBilling.feesApply;
    case constants.NHSD.billing.noFee:
      return constants.readableBilling.noFee;
    case constants.NHSD.billing.noFeeMeansTested:
      return constants.readableBilling.noFeeMeansTested;
    case constants.NHSD.billing.other:
      return constants.readableBilling.other;
    default:
      return constants.readableBilling.unavailable;
  }
}

export function capitaliseFirstLetter(string) {
  const firstLetter = string[0].toUpperCase();
  return [firstLetter, string.slice(1)].join("");
}

// Promise-based sleep function to help with google api management
export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function findAddressComponent(components, type) {
  for (let i = 0; i < components.length; i += 1) {
    if (components[i].types.includes(type)) return components[i].long_name;
  }
  return null;
}

export function isValidNhsdResponse(res) {
    return !res.meta.status.errors && res.data && res.data.length > 0
}

export function removeEmptyValuesFromObject(obj) {
    Object.keys(obj).forEach(key => {
        if (!obj[key]) {
            // eslint-disable-next-line no-param-reassign
            delete obj[key];
          }
    })
    return obj;
}

export function padNumberWithZeros(number, size) {
    let num = number.toString();
    while (num.length < size) num = `0${num}`;
    return num;
}
