import * as constants from "../resources/constants";

function getContact(contactData, idRef) {
    let value = constants.dataNA;
    contactData.forEach(contact => {
        if (contact.valueType.idRef === idRef) {
            value = contact.value;
        }
    });
    return value;
}

export function getFaxNumber(data) {
    return data.contact === null ? constants.dataNA : getContact(data.contacts, constants.NHSD.fax);
  }

export function getPhoneNumber(data) {
    return data.contact === null ? constants.dataNA : getContact(data.contacts, constants.NHSD.phone);
}
