import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid, Icon, Paper } from '@material-ui/core';
import { Error, Person, Work, Phone, Mail, SmsFailed } from '@material-ui/icons';

const useStyles = makeStyles({
    contactsBox: {
        width: '100%',
        borderRadius: 0,
        color: '#5d5d5d'
    },
    padGrid: {
        margin: '0.3em',
        marginBottom: '0.6em'
    },
    iconGrey: {
        '& .MuiSvgIcon-root': {
            fill: '#c0c0c0'
        },
        marginRight: '0.2em'
    },
    iconRed: {
        '& .MuiSvgIcon-root': {
            fill: '#af342d'
        },
        marginRight: '0.2em'
    }
});

export default function Contacts({ data }) {
    const classes = useStyles();
    return(
        <Paper elevation={2} className={classes.contactsBox}>
            <Grid container className={classes.padGrid}>
                <Grid container item xs={6}>
                    <Icon className={classes.iconRed}>
                        <Error />
                    </Icon>
                    <b>Internal use only</b>
                </Grid>
            </Grid>
            <Grid container className={classes.padGrid}>
                <Grid container item xs={6}>
                    <Icon className={classes.iconGrey}>
                        <Person />
                    </Icon>
                    {data.pName}
                </Grid>
                <Grid container item xs={6}>
                    <Icon className={classes.iconGrey}>
                        <Work />
                    </Icon>
                    {data.pRole}
                </Grid>
                <Grid container item xs={6}>
                    <Icon className={classes.iconGrey}>
                        <Phone />
                    </Icon>
                    {data.pPhone}
                </Grid>
                <Grid container item xs={6}>
                    <Icon className={classes.iconGrey}>
                        <Mail />
                    </Icon>
                    {data.pEmail}
                </Grid>
                <Grid container item xs={12}>
                    <Icon className={classes.iconGrey}>
                        <SmsFailed />
                    </Icon>
                    {data.pNote}
                </Grid>
            </Grid>
            <Grid container className={classes.padGrid}>
                <Grid container item xs={6}>
                    <Icon className={classes.iconGrey}>
                        <Person />
                    </Icon>
                    {data.sName}
                </Grid>
                <Grid container item xs={6}>
                    <Icon className={classes.iconGrey}>
                        <Work />
                    </Icon>
                    {data.sRole}
                </Grid>
                <Grid container item xs={6}>
                    <Icon className={classes.iconGrey}>
                        <Phone />
                    </Icon>
                    {data.sPhone}
                </Grid>
                <Grid container item xs={6}>
                    <Icon className={classes.iconGrey}>
                        <Mail />
                    </Icon>
                    {data.sEmail}
                </Grid>
                <Grid container item xs={12}>
                    <Icon className={classes.iconGrey}>
                        <SmsFailed />
                    </Icon>
                    {data.sNote}
                </Grid>
            </Grid>
        </Paper>
    );
}

Contacts.propTypes = {
    data: PropTypes.shape().isRequired
}
