import React from "react";
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
  }
});

export default function SearchButton({ onSearch, onReset, disabled }) {
  const classes = useStyles();
    return(
      <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={1}>
        <Grid item xs={6}>
          <Button variant="contained" color="primary" className={classes.button} onClick={onSearch} disabled={disabled} fullWidth>
            Search
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={onReset} variant="outlined" color="primary" align="center" className={classes.button} fullWidth>
            Reset
          </Button>
        </Grid>
      </Grid>
    );
  }

SearchButton.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

SearchButton.defaultProps = {
  disabled: false,
}