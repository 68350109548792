import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, {useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, InputAdornment, TextField, Typography } from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import * as constants from '../../resources/constants';

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            marginBottom: "0.2rem",
            "&:last-child": {
                marginBottom: "0"
            }
        },
    },
    tableRow: {
        backgroundColor: theme.palette.background.default,
        paddingLeft: "0.25rem",
        paddingRight: "0.25rem"
    },
    gridItem: {
        paddingLeft: "0.25rem",
        paddingRight: "0.25rem"
    },
    button: {
        marginLeft:"0.25rem",
        marginRight:"0.25rem"
    },
    buttonItem: {
        paddingRight: "2.25rem"
    },
    containerTop: {
        paddingTop: "0.5rem"
    },
    shortInput: {
        marginLeft: '0.1em',
        "&:last-child": {
            marginLeft: "0"
        },
        '& .MuiInputBase-input': {
            textAlign: 'center',
        },
        '& .MuiFilledInput-inputMarginDense': {
            paddingTop: '6px',
        },
        '& input': {
            width: '1.2em'
        }
    },
    gridContainer: {
        alignItems: "center"
    }
  }));

function formatDays(totalDays) {
    if (!totalDays) return null;
    const weeks = Math.floor(totalDays / 7);
    const days = totalDays % 7;

    return `${weeks}w ${days}d`;
}

function calculateUss(examDate, appointmentDate, reportDays, reportWeeks) {
    const examUtc = moment.utc(examDate);
    const apptUtc = moment.utc(appointmentDate);

    const dateToday = moment.utc();
    const gestationDays = reportWeeks * 7 + reportDays;
    const daysToTerm = (constants.gestationWeeks * 7) - gestationDays;
    const baseTimestamp = examUtc.add(daysToTerm, 'days');

    let valAppt;
    if (apptUtc) {
        const dayDiffAppt = baseTimestamp.diff(apptUtc, 'days');
        valAppt = constants.gestationWeeks * 7 - dayDiffAppt;
    }

    const dayDiffToday = baseTimestamp.diff(dateToday, 'days');
    const valToday = constants.gestationWeeks * 7 - dayDiffToday;

    return {
        today: formatDays(valToday),
        appt: formatDays(valAppt),
    }
}

function isValidCount(input) {
    const parsed = parseInt(input, 10);
    return input === '' || (!Number.isNaN(parsed) && (input.length >= 0 && input.length <=2));
}

export default function UltrasoundReportCalculator({ reset }) {
    const classes = useStyles();

    const [examDate, setExamDate] = useState(null);
    const [appointmentDate, setAppointmentDate] = useState(null);

    const [reportDays, setReportDays] = useState('');
    const [reportWeeks, setReportWeeks] = useState('');

    const [ussToday, setUssToday] = useState('')
    const [ussAppt, setUssAppt] = useState('')

    const [examError, setExamError] = useState(false);
    const [appointmentError, setAppointmentError] = useState(false);


    const getUss = () => {
        if (examDate) {
            const daysToUse = reportDays === '' ? 0 : reportDays;
            const weeksToUse = reportWeeks === '' ? 0 : reportWeeks;

            const ussObj = calculateUss(examDate, appointmentDate, parseInt(daysToUse, 10), parseInt(weeksToUse, 10));
            setUssToday(ussObj.today);
            setUssAppt(ussObj.appt);
        }
    };

    const onReset = () => {
        setExamDate(null);
        setAppointmentDate(null)
        setReportDays('');
        setReportWeeks('');
        setUssToday('');
        setUssAppt('');
        setExamError(false);
        setAppointmentError(false)
    }

    useEffect(() => {
        onReset()
    }, [reset])

    return (
        <div className={classes.root}>
            <Grid container direction="row" className={classes.containerTop}>
                <Grid item xs={6} className={classes.gridItem}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Exam date"
                            format="DD/MM/yyyy"
                            placeholder="dd/mm/yyyy"
                            emptyLabel="dd/mm/yyyy"
                            value={examDate}
                            onChange={date => {
                                if (!date.isValid()) {
                                    setExamError(true);
                                } else {
                                    setExamError(false);
                                    setExamDate(date)
                                }
                            }}
                            inputProps={{ spellCheck: 'false' }}
                            />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} className={classes.gridItem}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Appointment date"
                            format="DD/MM/yyyy"
                            placeholder="dd/mm/yyyy"
                            emptyLabel="dd/mm/yyyy"
                            value={appointmentDate}
                            onChange={date => {
                                if (!date.isValid()) {
                                    setAppointmentError(true);
                                } else {
                                    setAppointmentError(false);
                                    setAppointmentDate(date)
                                }
                            }}
                            inputProps={{ spellCheck: 'false' }}
                            />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="flex-start" justifyContent="center" className={classes.gridContainer}>
                <Grid item xs={3} className={classes.gridItem}>
                    <TextField
                        InputProps={{
                            endAdornment: <InputAdornment position="end">Weeks</InputAdornment>
                        }}
                        className={classes.shortInput}
                        variant="outlined"
                        value={reportWeeks}
                        onChange={(event) => {
                            const val = event.target.value
                            if(isValidCount(val)) {
                                setReportWeeks(val)
                            }
                        }}
                        />
                </Grid>
                <Grid item xs={3} className={classes.gridItem}>
                    <TextField
                        className={classes.shortInput}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">Days</InputAdornment>
                        }}
                        variant="outlined"
                        value={reportDays}
                        onChange={(event) => {
                            const val = event.target.value
                            if(isValidCount(val)) {
                                setReportDays(val)
                            }
                        }}
                        />
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={getUss} variant="contained" color="primary" align="center" disabled={!examDate || examError || appointmentError} className={classes.button}>
                        Calculate
                    </Button>
                    <Button onClick={onReset} variant="outlined" color="primary" align="center" className={classes.button}>
                       Reset
                    </Button>
                </Grid>
            </Grid>
            {ussToday &&
                    <>
                        <Grid container direction="row" alignItems="center" justifyContent="space-between">
                            <Grid item xs={6} className={classes.gridItem}>
                                <Typography variant="body2" align="left"> USS Today:</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.gridItem}>
                                <Typography variant="body2" align="left">USS Day of Appointment:</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.tableRow}>
                                <Typography variant="body2" align="left">
                                    {ussToday}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.tableRow}>
                                <Typography variant="body2" align="left">
                                    {ussAppt || "-"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
            }
        </div>
    );
}

UltrasoundReportCalculator.propTypes = {
    reset: PropTypes.bool.isRequired
}