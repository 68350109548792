import React, { useState, useEffect } from "react";
import { makeStyles, Grid, Chip, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { locations, facilityLabels } from '../../resources/constants';


const useStyles = makeStyles({
  root: {
  },
  filter: {
  },
  chip: {
    marginRight: "0.5rem",
    marginBottom: "0.25rem"
  }
});

/**
 * Filter results by clinic/hospital/radiology/pathology
 * @param {Function} setFilterValues 
 * @returns 
 */
const FacilityTypeFilter = ({ setFilterValues }) => {
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [options, setOptions] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    const tempFilterOptions = [];
    Object.keys(locations).forEach(key => {
      tempFilterOptions.push({
        value: locations[key],
        label: facilityLabels[key],
        isSelected: true
      })
    })
    setOptions(tempFilterOptions);
    setFilterValues(tempFilterOptions.filter(o => o.isSelected).map(o => o.value));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Callback for selecting options.
   * Select/deselect clicked option. 
   * Select/deslect 'all' if required.
   * @param {Oject} option 
   */
  const onSelect = (option) => {
    // setOptions(option);
    let isSelected;
    if (option.isSelected) {
      isSelected = false;
      setIsAllSelected(false);
    }
    else {
      isSelected = true;
      // check if all options are selected
      let allSelected = true;
      options.forEach(o => {
        if (!o.isSelected && o.value !== option.value) {
          allSelected = false;
        }
      })
      if (allSelected) {
        setIsAllSelected(true);
      }
    }

    const tempOptions = [...options];
    const index = options.findIndex(o => o.value === option.value);
    tempOptions[index].isSelected = isSelected;
    setOptions(tempOptions);
    setFilterValues(tempOptions.filter(o => o.isSelected).map(o => o.value));
  }

  /**
   * Callback for  'all' option.
   * Select/deselect 'all' option. 
   * Select/deselect all options where required.
   */
  const onSelectAll = () => {
    if (isAllSelected) {
      setIsAllSelected(false);
      // if all options are selected, deselect all
      let allSelected = true;
      options.forEach(o => {
        if (!o.isSelected) {
          allSelected = false;
        }
      })
      if (allSelected) {
        const tempOptions = [];
        options.forEach(o => {
          tempOptions.push({
            ...o,
            isSelected: false
          })
        });
        setOptions(tempOptions);
        setFilterValues(tempOptions.filter(o => o.isSelected).map(o => o.value));
      }
    }
    else {
      setIsAllSelected(true);
      // select all options
      const tempOptions = [];
      options.forEach(o => {
        tempOptions.push({
          ...o,
          isSelected: true
        })
      });
      setOptions(tempOptions);
      setFilterValues(tempOptions.filter(o => o.isSelected).map(o => o.value));
    }
  }

  return (
    <Grid container direction="row">
      <Grid item xs={2} align="left">
        <Typography align="center" variant="h6" color="textSecondary">
          Filter by
        </Typography>
      </Grid>
      <Grid item xs={10} align="left">
        <Chip
            label="All"
            color={isAllSelected ? "primary" : "default"}
            clickable
            onClick={onSelectAll}
            className={classes.chip}
          />
        {options.map(option => (
          <Chip
            key={option.value}
            label={option.label}
            color={option.isSelected ? "primary" : "default"}
            clickable
            onClick={() => onSelect(option)}
            className={classes.chip}
          />
        ))}
      </Grid> 
    </Grid> 
  )
}

FacilityTypeFilter.propTypes = {
  setFilterValues: PropTypes.func,
};

FacilityTypeFilter.defaultProps = {
  setFilterValues: () => {}
};

export default FacilityTypeFilter;