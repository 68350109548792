import MomentUtils from '@date-io/moment'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, {useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment, { Moment } from 'moment';
import * as constants from "../../resources/constants";

function calcGestationFromDates(LMP, ApptDate) {
    let text = "";
    const now = moment.utc();
    const diffTimeNow = now - LMP;
    const diffDaysNow = Math.floor(diffTimeNow/(1000*60*60*24));

    const diffTimeAppt = ApptDate - LMP;
    const diffDaysAppt = Math.floor(diffTimeAppt/(1000*60*60*24));

    const gestationNow = ((Math.trunc(diffDaysNow/7)) + ((diffDaysNow%7)/10)).toFixed(1);
    const gestationAppt = ((Math.trunc(diffDaysAppt/7)) + ((diffDaysAppt%7)/10)).toFixed(1);

    switch(true) {
        case(diffDaysAppt <= 34):
            text = constants.gestCalc.lessThan34Days;
            break;
        case(diffDaysAppt <= 55):
            text = constants.gestCalc.lessThan55Days;
            break;
        case(diffDaysAppt <= 62):
            text = constants.gestCalc.lessThan62Days;
            break;
        case(diffDaysAppt <= 167):
            text = constants.gestCalc.lessThan167Days;
            break;
        case(diffDaysAppt > 167):
            text = constants.gestCalc.greaterThan167Days;
            break;
        default:
            text = "";
            break;
    }

    return [diffDaysNow, diffDaysAppt, gestationNow, gestationAppt, text]
}

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            marginBottom: "1rem",
            "&:last-child": {
                marginBottom: "0"
            }
        },
    },
    tableRow: {
        backgroundColor: theme.palette.background.default,
        paddingLeft: "0.25rem",
        paddingRight: "0.25rem"
    },
    gridItem: {
        paddingLeft: "0.25rem",
        paddingRight: "0.25rem"
    },
    button: {
        marginLeft:"0.25rem",
        marginRight:"0.25rem"
    },
    buttonItem: {
        paddingRight: "2.25rem"
    },
    containerTop: {
        paddingTop: "0.5rem"
    }
  }));

export default function GestationCalculator({
    reset,
    lastMenstrualDate,
    setlastMenstrualDate,
    appointmentDate,
    setAppointmentDate,
    onResetGestation,
    calculateGestation,
    setCalculateGestation
}) {
    const classes = useStyles();

    const [gestation, handleDateChange] = useState("");

     const getGestation = () => handleDateChange(calcGestationFromDates(lastMenstrualDate, appointmentDate));

    const handleMenstrualDateChange = (date) => {
        setlastMenstrualDate(date.utc());
    };

    const handleAppointmentDateChange = (date) => {
        setAppointmentDate(date.utc());
    };

    const onReset = () => {
        setAppointmentDate(null);
        setlastMenstrualDate(null);
        handleDateChange("");
        onResetGestation();
    }

    useEffect(() => {
        onReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset])

    useEffect(() => {
        if (calculateGestation) {
            getGestation();
            setCalculateGestation(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculateGestation])

    return (
        <div className={classes.root}>
            <Grid container direction="row" className={classes.containerTop}>
                <Grid item xs={6} className={classes.gridItem}>
                    <MuiPickersUtilsProvider utils={MomentUtils} >
                        <KeyboardDatePicker
                        autoOk
                        disableFuture
                        variant="inline"
                        inputVariant="outlined"
                        label="Last Menstrual Period"
                        format="DD/MM"
                        placeholder="dd/mm"
                        emptyLabel="dd/mm"
                        value={lastMenstrualDate}
                        onChange={date => handleMenstrualDateChange(date)}
                        inputProps={{ spellCheck: 'false' }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} className={classes.gridItem}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Appointment Date"
                        format="DD/MM/yyyy"
                        placeholder="dd/mm/yyyy"
                        emptyLabel="dd/mm/yyyy"
                        value={appointmentDate}
                        onChange={date => handleAppointmentDateChange(date)}
                        inputProps={{ spellCheck: 'false' }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="flex-start" justifyContent="center">
                <Grid item xs={12} className={classes.buttonItem}>
                    <Button onClick={getGestation} variant="contained" color="primary" align="center" disabled={!lastMenstrualDate} className={classes.button}>
                        Calculate
                    </Button>
                    <Button onClick={onReset} variant="outlined" color="primary" align="center" className={classes.button}>
                        Reset
                    </Button>
                </Grid>
            </Grid>
            {gestation[4] && (
                <>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                        <Grid item xs={6} className={classes.gridItem}>
                            <Typography variant="body2" align="left"> Current Gestation:</Typography>
                        </Grid>
                        <Grid item xs={6} className={classes.gridItem}>
                            <Typography variant="body2" align="left">Gestation Day of Appointment:</Typography>
                        </Grid>
                        <Grid item xs={6} className={classes.tableRow}>
                            <Typography variant="body2" align="left">
                                {lastMenstrualDate ? `${gestation[2]} Week(s)` : "-"}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className={classes.tableRow}>
                            <Typography variant="body2" align="left">
                                {appointmentDate && lastMenstrualDate ? `${gestation[3]} Week(s)` : "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" justifyContent="space-around">
                        <Grid item xs className={classes.gridItem}>
                            <div styles={{width:"100%"}}>
                                <Typography variant="body2" align="left" style={{fontStyle: 'italic'}}>
                                    {gestation[4]}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
}

GestationCalculator.propTypes = {
    reset: PropTypes.bool.isRequired,
    lastMenstrualDate: PropTypes.instanceOf(Moment),
    appointmentDate: PropTypes.instanceOf(Moment),
    setlastMenstrualDate: PropTypes.func.isRequired,
    setAppointmentDate: PropTypes.func.isRequired,
    onResetGestation: PropTypes.func,
    calculateGestation: PropTypes.bool,
    setCalculateGestation: PropTypes.func
}

GestationCalculator.defaultProps = {
    lastMenstrualDate: null,
    appointmentDate: null,
    onResetGestation: () => {},
    calculateGestation: false,
    setCalculateGestation: () => {}
}