import bent from 'bent';
import * as constants from '../resources/constants';

const getJSON = bent('json');

export default async () => {
  const url = `${process.env.REACT_APP_API_URL}${constants.API_VERSION}${constants.routes.categories.all}`;
  const res = await getJSON(url);
  if (res.data && res.data.length > 0) {
    // nothing
  }
  else {
    res.data = [];
  }
  return res;
}