// eslint-disable-next-line import/prefer-default-export
export const scriptingMessagesSchema = {
    columns: [
    {
        field: 'id',
        headerName: 'ID',
        width: 90
    },
    {
        field: 'geography',
        headerName: 'Geography',
        width: 300,
        editable: true,
    },
    {
        field: 'u15_weeks',
        headerName: 'Under 15W',
        width: 400,
        editable: true,
    },
    {
        field: 'o15_weeks',
        headerName: 'Over 15W',
        width: 400,
        editable: true,
    },
    {
        field: 'note',
        headerName: 'Note',
        width: 300,
        editable: true,
    },
    {
        field: 'state_id',
        headerName: 'State ID',
        width: 150,
        editable: true,
    },
    ]
};