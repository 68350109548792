export const API_VERSION = 'v2/';

export const routes = {
    clinics: {
        all: 'clinics/',
        nearby: 'clinics/nearby/',
        crud: 'clinics/'
    },
    radiologies: {
        nearby:'radiologies/nearby/',
    },
    hospitals: {
        nearby: 'hospitals/nearby/',
    },
    pathologies: {
        nearby: 'pathologies/nearby/',
    },
    links: {
        all: 'resources/links',
        crud: 'resources/links/'
    },
    clinic_contacts: {
        all: 'clinicscontacts/',
        crud: 'clinicscontacts/'
    },
    feedback: {
        all: 'feedback/',
        crud: 'feedback/',
        openCount: 'feedback/open',
    },
    health_services: {
        all: 'healthservices/',
        crud: 'healthservices/'
    },
    states: {
        all: 'states/',
        crud: 'states/'
    },
    users: {
        all: 'users/',
        crud: 'users/',
    },
    categories: {
        all: 'categories/',
        crud: 'categories/'
    },
    clinics_services: {
        all: 'clinicshealthservices/',
        crud: 'clinicshealthservices/'
    },
    funding_pathways: {
        all: 'fundingpathways/',
        crud: 'fundingpathways/'
    },
    scripting_messages: {
        all: 'scriptingmessages/',
        crud: 'scriptingmessages/'
    },
}

export const params = {
    lat: 'lat=',
    lon: 'lon=',
    services: 'services=',
}

export const locations = {
    msclinic: 'msclinic',
    clinic: 'clinic',
    radiology: 'radiology',
    pathology: 'pathology',
    hospital: 'hospital',
}

export const facilityLabels = {
    clinic: 'Clinic',
    msclinic: 'MS Clinic',
    radiology: 'Radiology',
    pathology: 'Pathology',
    hospital: 'Hospital',
}

export const facilityColours = {
    clinic: '#EF9A9A',
    radiology: '#81D4FA',
    pathology: '#B39DDB',
    hospital: '#FFF59D',
    msclinic: '#FFAB91'
}

export const gestCalc = {
    lessThan34Days: 'You are eligible for a surgical, medical, or tele-abortion. Please be aware when you book that your appointment will be made for when you are more than 5 weeks pregnant, to ensure the ultrasound conducted can reliably confirm your pregnancy and the termination can proceed.',
    lessThan55Days: 'You are eligible for a surgical or medical abortion, or for a tele-abortion if you meet tele-abortion eligibiliy criteria and are no more than 8 weeks (56 days) on the day of your tele-abortion consultation.',
    lessThan62Days: 'You are eligible for a surgical abortion or a medical abortion if you are no more than 9 weeks (63 days) on the day of your medical abortion consultation.',
    lessThan167Days: 'You are eligible for a surgical abortion. Depending on your gestation and where you live, different gestation rules and limits may apply.',
    greaterThan167Days: 'Abortion laws are more restrictive after 24 weeks. In cases where it is an option, the termination is often only allowed with two doctors\' approval. We can advise you on the options you have available to you, based on where you live, through our free counseling service.',
}

export const NHSD = {
    fax: 'nhsd:/reference/common/contactType/fax',
    phone: 'nhsd:/reference/common/contactType/phone',
    billing: {
        bulkOnly: 'nhsd:/reference/common/feeAndBillingOption/bulkBillingOnly',
        byDonation: 'nhsd:/reference/common/feeAndBillingOption/byDonation',
        coPayment: 'nhsd:/reference/common/feeAndBillingOption/coPayment',
        feesAndBulkBilling: 'nhsd:/reference/common/feeAndBillingOption/feesAndBulkBilling',
        feesApply: 'nhsd:/reference/common/feeAndBillingOption/feesApply',
        noFee: 'nhsd:/reference/common/feeAndBillingOption/noFee',
        noFeeMeansTested: 'nhsd:/reference/common/feeAndBillingOption/noFeeMeansTested',
        other: 'nhsd:/reference/common/feeAndBillingOption/otherOption',
    }
}

// Retrieved from http://hl7.org.au/fhir/CodeSystem/service-provision-conditions
export const readableBilling = {
    noFee: "All or some of the services provided are offered free of charge.",
    noFeeMeansTested: "All or some of the services provided are offered free of charge, however the decision on the costs associated with the service or procedures offered will depend on a means test outcome.",
    bulkBillingOnly: "Bulk Billing is offered under Medicare.",
    feesApply: "All or some of the services provided are offered at the Healthcare Service",
    byDonation: "All or some of the services provided are offered on a donation basis. The expectation on the donation is not defined.",
    coPayment : "All or some of the services provided are subject to a co-payment, which may be in combination with Private Insurance.",
    feesAndBulkBilling: "All or some of the services provided are offered subject to a payment in combination with Bulk Billing.",
    other: "Depending on the service required, Bulk Billing may be available. Contact the provider to confirm what is available.",
    unavailable: 'Data unavailable.'
}

export const dataNA = 'No data';
export const failedGet = 'Failed to get data';
export const urlDelimiter = '-';

export const gestationWeeks = 40;
export const invalidDate = 'Invalid Date';

export const queryFields = {
    latitude: "lat",
    longitude: "long",
    serviceType: "service",
    lastMenstrualPeriod: "lmp",
    appointmentDate: "appt",
};

export const alerts = {
    limit: {
        state: "State Gestation Limit: ",
        clinic: "Clinic Gestation Limit: "
    },
    hospitalFar: "Telehealth Services (THH) are not available.",
    clinicFar: "Send rural client email and advise of ultrasound requirement.",
    hospitalDisclaimer: "Hospital emergency department data is from public sources and MSA does not warrant the completeness or accuracy of the data displayed.",
    holidayPrefix: "Holiday hours on ",
    closedPrefix: "Closed on ",
    noHolidays: "No reported holiday hours. Contact the service if unsure."
};