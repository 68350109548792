import { timeout } from './Utils';

// Place ID --> Lat Lon conversion
export function getLatLonPlaces (placeId, destination, callback){
    const request = { fields: ['geometry,address_component'], placeId };
    const service = new window.google.maps.places.PlacesService(destination);
    service.getDetails(request, callback);
}

// Lat Lon --> Address conversion
export async function getAddressPlaces (lat, lng, callback) {
  // We rely on this having loaded, but this component will mount before the script is loaded in most cases
  if (!window.google) {
    await timeout(3000);
  }

  const geocoder = new window.google.maps.Geocoder();
  geocoder
    .geocode({ location: {lat, lng} })
    .then((response) => {
      if (response.results[0]) {
        callback(response.results[0]);
      }
    })
}