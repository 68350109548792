import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5)
    }
  },
  chip: {
    marginRight: "0.5rem",
    marginBottom: "0.25rem"
  }
}));

/**
 * Displays service options as chips within categories
 * @param {Array<Object>} options
 * @param {Function} onSelect
 * @returns
 */
const ServiceFilter = ({ options, onSelect }) => {
  const [categories, setCategories] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    const tempCategories = [];
    options.forEach((option) => {
      if (!tempCategories.map((cat) => cat.value).includes(option.category)) {
        tempCategories.push({
          value: option.category,
          label: [option.category, '(all)'].join(' '),
          isAllSelected: false
        });
      }
    });
    tempCategories.sort((a, b) =>
      b.value.toLowerCase().includes("other") ? -1 : 0
    );
    setCategories(tempCategories.sort());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.length]);

  // useEffect(() => {
  //     console.log(categories);

  // }, [categories])

  /**
   * Select or deselect clicked option. Deselect the category if not all
   * options within the category are selected.
   * @param {Object} selected 
   */
  const onSelectChip = (selected) => {
    if (selected.isSelected) {
      // option was previously selected
      // deselect the category
      const tempCategories = [...categories];
      const index = tempCategories.findIndex(
        (category) => category.value === selected.category
      );
      tempCategories[index].isAllSelected = false;
      setCategories(tempCategories);
    }
    onSelect([selected]);
  };

  /**
   * Select or deselect all options in a category.
   * If all options in a cetegory are selected, they will all
   * be deselected by deselecting the category.
   * Selecting the category will select all unselected options.
   * @param {Object} selectedCategory 
   */
  const onSelectCategory = (selectedCategory) => {
    const tempCategories = [...categories];
    const index = tempCategories.findIndex(
      (category) => category.value === selectedCategory.value
    );
    if (selectedCategory.isAllSelected) {
      tempCategories[index].isAllSelected = false;
      setCategories(tempCategories);
      // TODO if all in a category are selected, deselect all
      let isAllSelected = true;
      options.forEach((option) => {
        if (option.category === selectedCategory.value && !option.isSelected) {
          isAllSelected = false;
        }
      });
      if (isAllSelected) {
        onSelect(
          options.filter(
            (option) =>
              option.category === selectedCategory.value && option.isSelected
          )
        );
      }
    } else {
      tempCategories[index].isAllSelected = true;
      setCategories(tempCategories);
      // Select only the options which aren't already selected
      onSelect(
        options.filter(
          (option) =>
            option.category === selectedCategory.value && !option.isSelected
        )
      );
    }
  };

  /**
   * Check whether a category should be selected based on whether all the 
   * options in it are selected.
   */
  useEffect(() => {
    if (options && options.length > 0 && categories && categories.length > 0) {
      // Check if each category needs to be selected if not already
      const tempCategories = [...categories];
      tempCategories.filter((cat) => !cat.isSelected).forEach(cat => {
        const isAnyOptionNotSelected = options.filter(
          option => option.category === cat.value && !option.isSelected
        ).length > 0;
        if (!isAnyOptionNotSelected) {
          // eslint-disable-next-line no-param-reassign
          cat.isAllSelected = true;
        }
        else {
          // eslint-disable-next-line no-param-reassign
          cat.isAllSelected = false;
        }
      });
      setCategories(tempCategories);

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <div className={classes.root}>
      {categories.map((cat) => (
        <Grid container direction="row" key={cat.value}>
          <Grid item xs={4} align="left">
            <Chip
              label={cat.label}
              color={cat.isAllSelected ? "primary" : "default"}
              clickable
              onClick={() => onSelectCategory(cat)}
              className={classes.chip}
            />
          </Grid>
          <Grid item xs={8} align="left">
            {options
              .filter((option) => option.category === cat.value)
              .map((option) => (
                <Chip
                  key={option.value}
                  label={option.label}
                  color={option.isSelected ? "primary" : "default"}
                  clickable
                  onClick={() => onSelectChip(option)}
                  className={classes.chip}
                />
              ))}
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

ServiceFilter.propTypes = {
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      category: PropTypes.string,
      isSelected: PropTypes.bool,
      id: PropTypes.number
    })
  )
};

ServiceFilter.defaultProps = {
  onSelect: () => {},
  options: PropTypes.shape({
    label: "",
    value: "",
    category: "",
    isSelected: false,
    id: ""
  })
};

export default ServiceFilter;
