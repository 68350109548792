// eslint-disable-next-line import/prefer-default-export
export const fundingPathwaysSchema = {
    columns: [
    {
        field: 'id',
        headerName: 'ID',
        width: 90
    },
    {
        field: 'postcode',
        headerName: 'Postcode',
        width: 150,
        editable: true,
    },
    {
        field: 'suburb',
        headerName: 'Suburb',
        width: 300,
        editable: true,
    },
    {
        field: 'scripting_message_id',
        headerName: 'Scripting Message ID',
        width: 400,
        editable: true,
    }
    ]
};