import bent from 'bent';
import * as constants from '../resources/constants';
import { showSuccessRes } from './Toast';
import { handleError } from './Utils';

const getJSON = bent('json');
const postJSON = bent('POST', 201);

// creates a new row in table
export async function addFeedback(msg, name) {
    try {
        const route = constants.routes.feedback.crud;
        const url = `${process.env.REACT_APP_API_URL}${constants.API_VERSION}${route}`;
        // Once authentication is added, we will need to modify submitted_by to match the logged in user where possible
        const nametoUse = name.length > 0 ? name : 'Anonymous';
        const newRow = {
            note: msg,
            'date_submitted': new Date().toISOString().split('.')[0],
            'date_completed': null,
            'submitted_by': nametoUse,
            'actioned_by': null
        }

        const res = await postJSON(url, newRow);
        showSuccessRes(res);
        return res;
    } catch(e) {
        const response = await handleError(e);
        return response;
    }
}

export async function getOpenFeedback() {
    try {
        const route = constants.routes.feedback.openCount;
        const url = `${process.env.REACT_APP_API_URL}${constants.API_VERSION}${route}`;
        const res = await getJSON(url);
        return res;
    } catch (e) {
        const response = await handleError(e)
        return response;
    }
}