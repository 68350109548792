// eslint-disable-next-line import/prefer-default-export
export const clinicsSchema = {
    columns: [
    {
        field: 'id',
        headerName: 'ID',
        width: 90,
    },
    {
        field: 'name',
        headerName: 'Name',
        width: 300,
        editable: true,
    },
    {
        field: 'add_line_1',
        headerName: 'Address 1',
        width: 150,
        editable: true,
    },
    {
        field: 'add_line_2',
        headerName: 'Address 2',
        width: 150,
        editable: true,
    },
    {
        field: 'add_line_3',
        headerName: 'Address 3',
        width: 150,
        editable: true,
    },
    {
        field: 'suburb',
        headerName: 'Suburb',
        width: 150,
        editable: true,
    },
    {
        field: 'state_id',
        headerName: 'State',
        width: 150,
        editable: true,
    },
    {
        field: 'postcode',
        headerName: 'Postcode',
        // This is just to avoid it formatting the postcodes like 2000 --> 2,000
        type: 'string',
        width: 150,
        editable: true,
    },
    {
        field: 'latitude',
        headerName: 'Latitude',
        width: 150,
        editable: true,
    },
    {
        field: 'longitude',
        headerName: 'Longitude',
        width: 150,
        editable: true,
    },
    {
        field: 'primary_contact_id',
        headerName: 'Primary Contact',
        type: 'number',
        width: 100,
        editable: true,
    },
    {
        field: 'secondary_contact_id',
        headerName: 'Secondary Contact',
        type: 'number',
        width: 100,
        editable: true,
    },
    {
        field: 'max_gest',
        headerName: 'Max. Gestation',
        width: 200,
        editable: true,
    },
    {
        field: 'owner',
        headerName: 'Owner',
        width: 150,
        editable: true,
    }
    ]
};