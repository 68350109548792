import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as constants from '../../resources/constants';

const marieStopesApi = createApi({
  reducerPath: 'marieStopesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}${constants.API_VERSION}`,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().auth;
      if (token) {
        headers.set('x-auth-token', token);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});

export default marieStopesApi;
