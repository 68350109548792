// eslint-disable-next-line import/prefer-default-export
export const categoriesSchema = {
    columns: [
    {
        field: 'id',
        headerName: 'ID',
        width: 90
    },
    {
        field: 'name',
        headerName: 'Name',
        width: 300,
        editable: true,
    }
    ]
};