import bent from "bent";
import * as constants from "../resources/constants";
import { showError } from "./Toast";

const getJSON = bent("json");

// Makes a call to the API to get clinics
// Inputs: Latitude, Longitude, Count
// Count is the number of results to return
// Output: JSON response with data and metadata
export default async (lat, lon, count, services) => {
    const url =
        `${process.env.REACT_APP_API_URL}${constants.API_VERSION}${constants.routes.clinics.nearby}${count}?${constants.params.lat}${lat}&${constants.params.lon}${lon}&${constants.params.services}${services}`;
    const res = await getJSON(url);
    if (res.data && res.data.length > 0) {
        res.data.forEach(clinic => {
            // eslint-disable-next-line no-param-reassign
            clinic.dataType = clinic.owner && clinic.owner.toLowerCase().includes("ms") ? constants.locations.msclinic : constants.locations.clinic;
        });
    } else {
        showError(`${constants.failedGet} from ${url}`);
        res.data = [];
    }

  return res;
};
