import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "0.5rem",
    marginBottom: "1rem",
    position: "relative",
  },
  card: {
  },
  cardContent: {
    paddingTop: "1.25rem",
    "&:last-child": {
      paddingBottom: "1rem"
    }
  },
  title: {
    width: "fit-content",
    position: "absolute",
    padding: "0 0.5rem 0 0.5rem",
    top: "-1rem",
    left: "1rem",
    backgroundColor: theme.palette.background.paper
  },
  typography: {
    width: "fit-content"
  }
}));

const SearchOptionCard = ( {children, title} ) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Card
        className={classes.card}
        variant="outlined"
      >
        <CardContent className={classes.cardContent}>
          {children}
        </CardContent>
      </Card>
      {title && (
        <div className={classes.title}>
          <Typography variant="h6" align="left" className={classes.typography} color="textSecondary">
            {title}
          </Typography>
        </div>
      )}
    </div>
  );
};

SearchOptionCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
};

SearchOptionCard.defaultProps = {
  title: null,
};

export default SearchOptionCard;