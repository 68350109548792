import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from 'react-router-dom'
import { Button, ButtonGroup, Grid, makeStyles } from "@material-ui/core";
import AdminTable from '../components/AdminTable/AdminTable'

function findChildRoot(locationObj) {
  const path = locationObj.pathname;
  return path.split('/').pop();
}

const useStyles = makeStyles({
  root: {
    paddingLeft: '0.75em',
    paddingRight: '0.75em'
  },
  loadingIcon: {
    position: 'relative',
    top: '50%',
  },
  marginTop: {
    marginTop: '1em'
  }
});

function setButtonStatus(route, buttons) {
  buttons.forEach(table => {
    if (table.route === route) {
      table.cb(true);
    } else {
      table.cb(false);
    }
  })
}

export default function Admin() {
  const [isClinics, setIsClinics] = useState(false);
  const [isContacts, setIsContacts] = useState(false);
  const [isFeedback, setIsFeedback] = useState(false);
  const [isServices, setIsServices] = useState(false);
  const [isLinks, setIsLinks] = useState(false);
  const [isClinicsServices, setIsClinicsServices] = useState(false);
  const [isStates, setisStates] = useState(false);
  const [isUsers, setIsUsers] = useState(false);
  const [isCategories, setIsCategories] = useState(false);
  const [isFundingPathways, setIsFundingPathways] = useState(false);
  const [isScriptingMessages, setIsScriptingMessages] = useState(false);

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const tableName = findChildRoot(location);

  const tables = [
    {name: 'Categories', route: 'categories', state: isCategories, cb: setIsCategories},
    {name: 'Clinics', route: 'clinics', state: isClinics, cb: setIsClinics},
    {name: 'Clinics-Services', route: 'clinics_services', state: isClinicsServices, cb: setIsClinicsServices},
    {name: 'Contacts', route: 'clinic_contacts', state: isContacts, cb: setIsContacts},
    {name: 'Feedback', route: 'feedback', state: isFeedback, cb: setIsFeedback},
    {name: 'Links', route: 'links', state: isLinks, cb: setIsLinks},
    {name: 'Services', route: 'health_services', state: isServices, cb: setIsServices},
    {name: 'States', route: 'states', state: isStates, cb: setisStates},
    {name: 'Users', route: 'users', state: isUsers, cb: setIsUsers},
    {name: 'Funding Pathways', route: 'funding_pathways', state: isFundingPathways, cb: setIsFundingPathways},
    {name: 'Scripting', route: 'scripting_messages', state: isScriptingMessages, cb: setIsScriptingMessages},
    ]

  const navigateTo = (route) => {
    history.push(`/admin/${route}`);
    setButtonStatus(route, tables);
  };

  useEffect(() => {
    setButtonStatus(tableName, tables)
    // Ignoring because tables shouldn't cause this to re-run
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableName]);

      return(
        <Grid container spacing={1} className={classes.root}>
          <Grid item xs={1}>
            <ButtonGroup orientation="vertical" color="primary" variant="contained" aria-label="primary button group">
              {
                tables.map((table) => <Button key={table.route} disabled={table.state} onClick={() => navigateTo(table.route)}>{table.name}</Button>)
              }
            </ButtonGroup>
          </Grid>
          <Grid item xs={11}>
            <AdminTable tableName={tableName} add/>
          </Grid>
        </Grid>
      );
  }