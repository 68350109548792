import React, { useState } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonGroup, CircularProgress, Grid, TextField } from '@material-ui/core';
import { addFeedback } from "../../scripts/FeedbackService";

const useStyles = makeStyles({
    width: {
        width: '100%',
    }
  });

  export default function Feedback({context, onCancel }) {
    const classes = useStyles();
    const [isLoading, setLoading] = useState(false);
    const [text, setText] = useState('');
    const [name, setName] = useState('');

    const onSubmit = async () => {
        const textValueBeforeSubmit = text;
        setLoading(true);
        const res = await addFeedback(`${context} | ${text}`, name);
        setLoading(false);
        setText(textValueBeforeSubmit);
        if (res != null) onCancel();
    }

    const handleChange = (event) => {
        setText(event.target.value)
    }

    const handleNameChange = (event) => {
        setName(event.target.value)
    }

    return(
        <div className={classes.width}>
        {isLoading ?
        <CircularProgress /> :
        <Grid container spacing={1}>
            <Grid container item xs={10}>
                <TextField
                    className={classes.width}
                    id="outlined-multiline-static"
                    label="Feedback"
                    multiline
                    rows={4}
                    variant="outlined"
                    placeholder="Please enter as much detail as possible."
                    value={text}
                    onChange={handleChange}
                    />
            </Grid>
            <Grid container item xs={2} justifyContent="center">
                <TextField
                    className={classes.width}
                    label="Name"
                    variant="outlined"
                    value={name}
                    onChange={handleNameChange}
                    />
                <ButtonGroup
                    size="small"
                    color="primary"
                    variant="contained"
                    disableElevation
                    >
                    <Button onClick={onSubmit}>Submit</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </ButtonGroup>
            </Grid>
        </Grid>
        }
        </div>
    );
}

Feedback.propTypes = {
    context: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
}