// eslint-disable-next-line import/prefer-default-export
export const contactsSchema = {
    columns: [
    {
        field: 'id',
        headerName: 'ID',
        width: 90
    },
    {
        field: 'name',
        headerName: 'Name',
        width: 300,
        editable: true,
    },
    {
        field: 'role',
        headerName: 'Role',
        width: 150,
        editable: true,
    },
    {
        field: 'email',
        headerName: 'E-mail',
        width: 150,
        editable: true,
    },
    {
        field: 'phone',
        headerName: 'Phone',
        width: 150,
        editable: true,
    },
    {
        field: 'note',
        headerName: 'Note',
        width: 150,
        editable: true,
    }
    ]
};