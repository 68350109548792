import React from "react";

// eslint-disable-next-line import/prefer-default-export
export const clinicsServicesSchema = {
    columns: [
    {
        field: 'id',
        renderHeader: () => (<div />),
    },
    {
        field: 'clinic_id',
        headerName: 'Clinic ID',
        width: 150,
        editable: true,
    },
    {
        field: 'health_service_id',
        headerName: 'Health Service Id',
        width: 200,
        editable: true,
    }]
};