/* eslint-disable no-param-reassign */
// Redux Toolkit behind the scenes uses Immer to ensure updates to
// existing data is done properly (which is what this ES Lint rule is fussing over).
//
// Check out https://redux-toolkit.js.org/usage/immer-reducers for more info on it.
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: null,
  token: null,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.email = action.payload.email;
      state.token = action.payload.token;
    },
  },
});

export const { setCredentials } = slice.actions;

export const selectCurrentUser = (state) => state.auth.email;

export default slice.reducer;
