import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { createTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import Protect from 'react-app-protect';
import 'react-app-protect/dist/index.css';
import { Provider } from 'react-redux';
import store from './redux/app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5990f7',
    },
    background: {
      default: '#e0e0e0',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          {process.env.NODE_ENV === 'production' ? (
            <>
              <Protect sha512={process.env.REACT_APP_PROTECT_PASSWORD}>
                <App />
              </Protect>
            </>
          ) : (
            <App />
          )}
        </Router>
      </MuiThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
