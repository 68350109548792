import bent from 'bent';
import * as constants from '../resources/constants';
import { showError } from './Toast';

const getJSON = bent('json');

// Makes a call to the API to get Links
// Output: JSON response with data and metadata
export default async () => {
    const url =
        `${process.env.REACT_APP_API_URL}${constants.API_VERSION}${constants.routes.links.all}`;
    try {
        const res = await getJSON(url);
        return res;
    } catch(e) {
        showError(`${constants.failedGet} from ${url}`);
        return { data: []}
    }
}