import bent from "bent";
import _ from "lodash";
import store from "../redux/app/store";
import { clinicsSchema } from "../resources/schema/clinics";
import { contactsSchema } from "../resources/schema/contacts";
import { feedbackSchema } from "../resources/schema/feedback";
import { servicesSchema } from "../resources/schema/services";
import { linksSchema } from "../resources/schema/links";
import { statesSchema } from "../resources/schema/states";
import { usersSchema } from "../resources/schema/users";
import { dummySchema } from "../resources/schema/dummyResponse";
import { categoriesSchema } from "../resources/schema/categories";
import { clinicsServicesSchema } from "../resources/schema/clinicsServices";
import { fundingPathwaysSchema } from "../resources/schema/fundingPathways";
import { scriptingMessagesSchema } from "../resources/schema/scriptingMessages";
import * as constants from "../resources/constants";
import { showError, showSuccessRes } from "./Toast";
import { fixObjForDatabase, handleError } from "./Utils";

const getJSON = bent("json");
const putJSON = bent("PUT", 201);
const postJSON = bent("POST", 201);
const del = bent("DELETE", 200);

// Retrieves the schema based no the table name
// Will return a dummy schema if the tablename is invalid
function getSchema(tableName) {
  switch (tableName) {
    case "clinics":
      return clinicsSchema.columns;
    case "clinic_contacts":
      return contactsSchema.columns;
    case "feedback":
      return feedbackSchema.columns;
    case "health_services":
      return servicesSchema.columns;
    case "links":
      return linksSchema.columns;
    case "states":
      return statesSchema.columns;
    case "users":
      return usersSchema.columns;
    case "categories":
      return categoriesSchema.columns;
    case "clinics_services":
      return clinicsServicesSchema.columns;
    case "funding_pathways":
      return fundingPathwaysSchema.columns;
    case "scripting_messages":
      return scriptingMessagesSchema.columns;
    default:
      return dummySchema.columns;
  }
}

function GetAuthHeaders() {
  const state = store.getState();
  return { "x-auth-token": state.auth.token };
}

// Method to dynamically get a table based on tablename
export async function getTable(tableName) {
  try {
    const route = constants.routes[tableName].all;
    const url = `${process.env.REACT_APP_API_URL}${constants.API_VERSION}${route}`;

    const columns = getSchema(tableName);
    const { data } = await getJSON(url);
    // x-data-grid requires rows to have a unique ID. Some tables don't have this, i.e bridge tables
    if (!data[0].id) {
      data.forEach((row) => {
        // eslint-disable-next-line no-param-reassign
        row.id = _.uniqueId(tableName);
      });
    }

    return { tableRows: data, tableColumns: columns };
  } catch (e) {
    showError(e.toString());
    return { tableRows: dummySchema.rows, tableColumns: getSchema() };
  }
}

// Updates a table using PUT route
export async function updateTable(tableName, row) {
  try {
    const route = constants.routes[tableName].crud;
    const url = `${process.env.REACT_APP_API_URL}${constants.API_VERSION}${route}${row.id}`;
    const updatedRow = fixObjForDatabase(row);
    delete updatedRow.id;
    const authHeaders = GetAuthHeaders();
    const res = await putJSON(url, updatedRow, authHeaders);
    showSuccessRes(res);
    return res;
  } catch (e) {
    const response = await handleError(e);
    return response;
  }
}

// creates a new row in table
export async function createRow(tableName, row) {
  try {
    const route = constants.routes[tableName].crud;
    let url;
    let newRowToAdd;
    if (tableName === "clinics_services") {
      url = `${process.env.REACT_APP_API_URL}${constants.API_VERSION}${route}${row.clinic_id}/${row.health_service_id}`;
    } else {
      url = `${process.env.REACT_APP_API_URL}${constants.API_VERSION}${route}`;
      newRowToAdd = fixObjForDatabase(row);
      delete newRowToAdd.id;
    }
    const authHeaders = GetAuthHeaders();
    const res = await postJSON(url, newRowToAdd, authHeaders);
    showSuccessRes(res);
    return res;
  } catch (e) {
    const response = await handleError(e);
    return response;
  }
}

// deletes a row based on id
export async function deleteRow(tableName, row) {
  try {
    const route = constants.routes[tableName].crud;
    let url;
    if (tableName === "clinics_services") {
      url = `${process.env.REACT_APP_API_URL}${constants.API_VERSION}${route}${row.clinic_id}/${row.health_service_id}`;
    } else {
      url = `${process.env.REACT_APP_API_URL}${constants.API_VERSION}${route}${row.id}`;
    }
    const authHeaders = GetAuthHeaders();
    const res = await del(url, null, authHeaders);
    showSuccessRes(res);
    return res;
  } catch (e) {
    const response = await handleError(e);
    return response;
  }
}
