import React, { useState } from "react";
import { CircularProgress, Grid, makeStyles} from "@material-ui/core";
import LeftPanel from '../components/LeftPanel/LeftPanel';
import RightPanel from "../components/RightPanel/RightPanel";

const useStyles = makeStyles({
  root: {
    paddingLeft: '0.75em',
    paddingRight: '0.75em'
  },
  loadingIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  }
});

export default function Lookup() {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const resultsCallback = (res) => {
    setResults(res)
  }

  const loadingCallback = (state) => {
    setIsLoading(state);
  }

    return(
        <div>
          <Grid className={classes.root} container spacing={1}>
            <LeftPanel resultsCallback={resultsCallback} loadingCallback={loadingCallback} />
            <RightPanel results={results}/>
            {isLoading &&
              <CircularProgress className={classes.loadingIcon} size="7em" />
            }
          </Grid>
        </div>
      );
  }
