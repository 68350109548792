import React, { useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PropTypes from 'prop-types';
import convertTime from "../../scripts/Utils";
import * as constants from "../../resources/constants";

const useStyles = makeStyles({
    alert: {
        marginBottom: '0.3em',
        width: '100%',
        textAlign: 'center',
        '& .MuiAlert-message': {
            width: '100%',
            textAlign: 'center',
        }
    },
    expand: {
        width: '100%',
        marginTop: '0.2em'
    },
});
  /**
   * Takes an array of closedRules and returns the next day the location will be closed
   * @param {Array} closedRules: array of closedRules from NHSD api
   * @param {function} callback: callback function to set state
   */
function getNextClosedDay(closedRules, callback) {
    const closedDates = [];
    closedRules.forEach(rule => {
        const convertedTime = Date.parse(rule.closedDate)
        if (convertedTime - Date.now() < 0) return;
        closedDates.push({
            date: rule.closedDate,
            timeDiff: convertedTime - Date.now(),
            name: rule.reason.valueType.label
        });
    });
    closedDates.sort((a, b) => a.timeDiff - b.timeDiff);
    if (closedDates[0]) {
        callback({
            name: closedDates[0].name,
            date: closedDates[0].date,
            found: true
        });
    }
}

  /**
   * Takes an array of openRules and returns the next day the opening hours will be an exception to the weekly rule
   * @param {Array} closedRules: array of openrules from NHSD api
   * @param {function} callback: callback function to set state
   */
function getNextExceptionDay(openRules, callback) {
    const openDates = [];
    openRules.forEach(rule => {
        const convertedTime = Date.parse(rule.referenceDate)
        if (convertedTime - Date.now() < 0 || rule.pattern !== "HOLIDAY") return;
        openDates.push({
            date: rule.referenceDate,
            timeDiff: convertedTime - Date.now(),
            name: rule.holiday.valueType.label,
            open: convertTime(rule.openFrom),
            close: convertTime(rule.openTo)
        });
    });
    openDates.sort((a, b) => a.timeDiff - b.timeDiff);
    if (openDates[0]) {
        callback({
            name: openDates[0].name,
            date: openDates[0].date,
            openTime: openDates[0].open,
            closeTime: openDates[0].close,
            found: true
        });
    }
}

export default function LocationAlerts({ data, distance, dataType }) {
    const [nextHoliday, setNextHoliday] = useState({ name: '', date: '', found: false })
    const [nextException, setNextException] = useState({ name: '', date: '', found: false, openTime: '', closeTime: '' })
    const [shouldShowGestation, setShouldShowGestation] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        if(data.max_gest || data.max_gest_state) setShouldShowGestation(true)
        if (data.calendar) {
            getNextExceptionDay(data.calendar.openRule, setNextException);
            getNextClosedDay(data.calendar.closedRule, setNextHoliday);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <div className={classes.expand}>
            {shouldShowGestation &&
                <MuiAlert className={classes.alert} variant="outlined" severity="info">
                    {data.max_gest && data.max_gest.length > 0 &&
                        <div> {constants.alerts.limit.state}{data.max_gest} </div>
                    }
                    {constants.alerts.limit.state}{data.max_gest_state}
                </MuiAlert>
            }
            {dataType === constants.locations.hospital && parseInt(distance, 10) >= 200 &&
                  <MuiAlert className={classes.alert} variant="filled" severity="warning">
                    {constants.alerts.hospitalFar}
                  </MuiAlert>
            }
             {(dataType === constants.locations.clinic || dataType === constants.locations.msclinic) && parseInt(distance, 10) >= 200 &&
                  <MuiAlert className={classes.alert} variant="filled" severity="warning">
                    {constants.alerts.clinicFar}
                  </MuiAlert>
            }
            {dataType === constants.locations.hospital  &&
                  <MuiAlert className={classes.alert} variant="outlined" severity="info">
                    {constants.alerts.hospitalDisclaimer}
                  </MuiAlert>
            }
            {nextException.found &&
                <MuiAlert className={classes.alert} icon={<EventNoteIcon />} variant="filled" severity="info">
                    {constants.alerts.holidayPrefix}{nextException.name} ({nextException.date}): {nextException.openTime} - {nextException.closeTime}
                </MuiAlert>
            }
            {!nextException.found && !nextHoliday.found &&
                <MuiAlert className={classes.alert} icon={<EventNoteIcon />} variant="filled" severity="info">
                    {constants.alerts.noHolidays}
                </MuiAlert>
            }
            {nextHoliday.found &&
                <MuiAlert className={classes.alert} icon={<EventBusyIcon />} variant="filled" severity="warning">
                    {constants.alerts.closedPrefix}{nextHoliday.name} ({nextHoliday.date})
                </MuiAlert>
            }
        </div>
    );
}

LocationAlerts.propTypes = {
    data: PropTypes.shape({
        calendar: PropTypes.shape({
            openRule: PropTypes.arrayOf(PropTypes.shape({
                pattern: PropTypes.string.isRequired,
                openFrom: PropTypes.string.isRequired,
                openTo: PropTypes.string.isRequired,
                referenceDate: PropTypes.string.isRequired,
            })),
            closedRule: PropTypes.arrayOf(PropTypes.shape({
                closedDate: PropTypes.string.isRequired,
                reason: PropTypes.shape({
                    valueType: PropTypes.shape({
                        idRef: PropTypes.string.isRequired,
                        label: PropTypes.string.isRequired,
                    })
                }).isRequired,
            })),
        }),
        max_gest: PropTypes.string,
        max_gest_state: PropTypes.string
    }).isRequired,
    dataType: PropTypes.string.isRequired,
    distance: PropTypes.string.isRequired
};
