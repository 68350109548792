import React, { useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import PropTypes from 'prop-types';
import SearchBar from "../searchbar/SearchBar";
import SearchButton from "../SearchButton/SearchButton";
import getPlacesToken from "../../scripts/PlacesAutocomplete";
import { getLatLonPlaces, getAddressPlaces } from "../../scripts/PlacesDetails";
import getClinics from "../../scripts/ClinicsService";
import getRadiologies from "../../scripts/RadiologiesService";
import getPathologies from "../../scripts/PathologiesService";
import getHospitals from "../../scripts/HospitalsService";
import getHealthServices from "../../scripts/HealthServicesService";
import getCategories from "../../scripts/CategoriesService";
import ServiceFilter from "../ServiceFilter/ServiceFilter";
import GestationCalculator from "../GestationCalculator/GestationCalculator";
import SearchOptionCard from "../SearchOptionCard/SearchOptionCard";
import UltrasoundReportCalculator from "../UltrasoundReportCalculator/UltrasoundReportCalculator";
import { urlDelimiter,  queryFields } from "../../resources/constants";
import { removeEmptyValuesFromObject, padNumberWithZeros, findAddressComponent } from "../../scripts/Utils";
import AlternativeFundingPathways from "../AlternativeFundingPathways/AlternativeFundingPathways";

const useStyles = makeStyles({
  pad: {
    padding: '0.5em'
  },
  scroll: {
    overflowY: "auto",
    height: "calc(100vh - 150px)",
  }
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function formatDateForUrl(date) {
  try {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth is zero-based
    const day = date.getDate();
    return [year, padNumberWithZeros(month, 2), padNumberWithZeros(day, 2)].join("");
  } catch (error) {
    return "";
  }
}

function getDateFromUrlString(string) {
  if (string.length !==8 ) {
    return null;
  }
  const year = Number.parseInt(string.slice(0,4), 10);
  const monthIndex = Number.parseInt(string.slice(4,6), 10) - 1;
  const day = Number.parseInt(string.slice(6,8), 10);
  const date = new Date(year, monthIndex, day);
  if (Number.isNaN(date.getTime())) {
    return null;
  }
  return date;
}

export default function LeftPanel({ resultsCallback, loadingCallback }) {
  const googleToken = useRef(null);
  const [placeId, setPlaceId] = useState('');
  const [hasError, setError] = useState(false);
  const [results, setResults] = useState([]);
  const [queryTerms, setQueryTerms] = useState({});
  const [serviceOptions, setServiceOptions] = useState([]);
  const [locationQueryError, setLocationQueryError] = useState("");
  const [showGestationCalculator, setShowGestationCalculator] = useState(true);
  const [defaultAddress, setDefaultAddress] = useState(null);
  const [reset, setReset] = useState(false);
  const [lastMenstrualDate, setlastMenstrualDate] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [calculateGestation, setCalculateGestation] = useState(false);
  const [suburbData, setSuburbData] = useState(null);

  const history = useHistory();
  const classes = useStyles();
  const query = useQuery();


  /**
   * Contructs a url query string from selected filters for easy
   * copy/pasting after a search
   * @param {Object} terms
   * @returns {string}
   */
  const constructQuery = (terms) => {
    const tempTerms = {...terms};

    // Format into strings
    if (terms[queryFields.serviceType]) {
      tempTerms[queryFields.serviceType] = terms[queryFields.serviceType].join(urlDelimiter);
    }
    if (terms[queryFields.lastMenstrualPeriod]) {
      tempTerms[queryFields.lastMenstrualPeriod] = formatDateForUrl(terms[queryFields.lastMenstrualPeriod])
    }
    if (terms[queryFields.appointmentDate]) {
      tempTerms[queryFields.appointmentDate] = formatDateForUrl(terms[queryFields.appointmentDate])
    }

    const params = new URLSearchParams(removeEmptyValuesFromObject(tempTerms));
    const paramString = `?${params.toString()}`;
    return paramString;
  }

  /** This is called in 2 scenarios:
   * 1. The user is using the ADDRESS search
   * 2. The user is using the postcode search and there are no duplicate suburbs found for the given postcode
   *
   * We set the placeId so the search can work
  */
  const callbackSearchBar = (placeIdInput) => {
    setError(false);
    setLocationQueryError("");
    setPlaceId(placeIdInput);
  }

  /**
   * Calls API to get filtered clinic etc. results
   * @param {*} place
   * @param {*} status
   * @param {Object} optionalQueryTerms Required including lat and long if no place and status are given. Used to force up-to-date url creation in first render
   * @returns
   */
  const getResults = async (
    place,
    status,
    optionalQueryTerms
  ) => {
    const tempQueryTerms = {...(optionalQueryTerms || queryTerms)};
    let lat;
    let lon;
    if (place && status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        setError(true);
        return;
      }
      lat = place.geometry.location.lat();
      lon =  place.geometry.location.lng();

      tempQueryTerms[queryFields.latitude] = lat;
      tempQueryTerms[queryFields.longitude] = lon;
      setQueryTerms(tempQueryTerms);

      // Set the suburb data
      const resultPostcode = findAddressComponent(place.address_components, 'postal_code');
      const resultSuburbName = findAddressComponent(place.address_components, 'locality')
      setSuburbData({
        postcode: resultPostcode,
        suburb: resultSuburbName
      })

      // We need a new token after getting place info
      googleToken.current = getPlacesToken();
    }
    else if (optionalQueryTerms && optionalQueryTerms[queryFields.latitude] && optionalQueryTerms[queryFields.longitude]) {
      lat = optionalQueryTerms[queryFields.latitude];
      lon = optionalQueryTerms[queryFields.longitude];
    }

    history.push(constructQuery(tempQueryTerms));

    const serviceValues =  (optionalQueryTerms ? optionalQueryTerms[queryFields.serviceType] : queryTerms[queryFields.serviceType]) || [];
    const serviceIds = serviceOptions.filter(service => serviceValues.includes(service.value)).map(service => service.id);
    const servicesString = serviceIds.join(urlDelimiter);

    const [clinicsResults, radiologyResults, pathologyResults, hospitalResults] =
        await Promise.allSettled([
          getClinics(lat, lon, 5, servicesString),
          getRadiologies(lat, lon, 5),
          getPathologies(lat, lon, 5),
          getHospitals(lat, lon, 3)
        ]);

    const apiResponses = [clinicsResults, hospitalResults, radiologyResults, pathologyResults];
    let resultsFromResponse = []
    apiResponses.forEach(result => {
      if (result.value && result.value.data) resultsFromResponse = [...resultsFromResponse, ...result.value.data]
    })
    loadingCallback(false);
    setResults(resultsFromResponse);
  }

  const onSearch = () => {
    loadingCallback(true);
    if (placeId) {
      // This method will only get us the lat and lon of the place we got from the autocomplete.
      getLatLonPlaces(placeId, document.createElement('div'), getResults);
    }
    else if (queryTerms[queryFields.latitude] && queryTerms[queryFields.longitude]) {
      getResults(null, null, queryTerms);
    }
  }

  /**
   * Clear search parameters and results
   */
  const onReset = () => {
    // Reset state
    loadingCallback(false)
    setResults([])
    resultsCallback()
    callbackSearchBar('')
    setSuburbData(null);

    // Clear search bar & gest calc
    setReset(!reset)

    // Clear service options
    const tempServiceOptions = [];
    serviceOptions.forEach(service => {
      tempServiceOptions.push({
        ...service,
        isSelected: false,
      });
    })
    setServiceOptions(tempServiceOptions);
    setQueryTerms({});
    history.push(constructQuery({}));
  }

  // Callback for after we've reverse geocoded
  const getAddressCallback = (addr) => {
    const newSuburbObj = {}
    addr.address_components.forEach(component => {
      if (component.types.includes('postal_code')) newSuburbObj.postcode = component.long_name;
      if (component.types.includes('locality')) newSuburbObj.suburb = component.long_name;
    })

    setSuburbData(newSuburbObj);
    setDefaultAddress(addr.formatted_address);
  }

  useEffect(() => {
    if (googleToken === null) {
      googleToken.current = getPlacesToken();
    }
    if (results && results.length > 0) {
      resultsCallback(results);
    }
  }, [googleToken, results, resultsCallback])

  /**
   * Load health services and categories from the API for the service filter
   */
  const getServiceOptions = async () => {
    const [services, categories] = await Promise.all([getHealthServices(), getCategories()])
    const tempOptions = [];
    services.data.forEach(service => {
      const serviceValue = service.service_type.replaceAll(/[^a-zA-z0-9_-]/g, '');
      let category = "Other";
      if (service.category_id) {
        category = categories.data.find(cat => cat.id === service.category_id).name;
      }
      tempOptions.push({
          label: service.service_type,
          value: serviceValue,
          category,
          isSelected: false,
          id: service.id
      })
    })
    setServiceOptions(tempOptions);
  }

  useEffect(() => {
    getServiceOptions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Callback for service filter
   * Maintains service list state
   * @param {Object} selectedOptions
   */
  const onSelectService = (selectedOptions) => {
    const selectedValues = selectedOptions.map(option => option.value);
    const tempServiceOptions = [...serviceOptions];
    tempServiceOptions.forEach((option, index) => {
      if (selectedValues.includes(option.value)) {
        if (option.isSelected) {
          // deselect option
          tempServiceOptions[index].isSelected = false;
        }
        else {
          // select option
          tempServiceOptions[index].isSelected = true;
        }
      }
    })
    setServiceOptions(tempServiceOptions);

    // eslint-disable-next-line no-shadow
    setQueryTerms(queryTerms => ({
      ...queryTerms,
      [queryFields.serviceType]: tempServiceOptions.filter(service => service.isSelected).map(service => service.value)
    }));
    // TODO logic for showing gestation calculator
    setShowGestationCalculator(true);
  }

  /**
   * Extract query terms from the url
   */
  useEffect(() => { // retrieve search parameters from url
    if (serviceOptions.length > 0) {
      const tempQueryTerms = {};

      // Client location latitude and longitude
      const lat = query.get(queryFields.latitude);
      const long = query.get(queryFields.longitude);
      if (lat && long) {
        tempQueryTerms[queryFields.latitude] = Number.parseFloat(lat, 10);
        tempQueryTerms[queryFields.longitude] = Number.parseFloat(long, 10);
        getAddressPlaces(tempQueryTerms[queryFields.latitude], tempQueryTerms[queryFields.longitude], getAddressCallback)
      }

      // service
      const servicesString = query.get(queryFields.serviceType);
      const serviceQuery = [];
      if (servicesString) {
        const services = servicesString.split(urlDelimiter);
        const serviceOptionsValues = serviceOptions.map(option => option.value);
        services.forEach(service => {
          if (serviceOptionsValues.includes(service)) {
            serviceQuery.push(service);
          }
        })
        if (serviceQuery.length>0) {
          tempQueryTerms[queryFields.serviceType] = serviceQuery;
        }
        onSelectService(serviceQuery.map(serviceValue => ({value: serviceValue})));
      }

      // Gestation
      const lmpString = query.get(queryFields.lastMenstrualPeriod);
      if (lmpString) {
        const date = getDateFromUrlString(lmpString);
        setlastMenstrualDate(date);
        tempQueryTerms[queryFields.lastMenstrualPeriod] = date;
      }

      const apptDateString = query.get(queryFields.appointmentDate);
      if (apptDateString) {
        const date = getDateFromUrlString(apptDateString);
        setAppointmentDate(date);
        tempQueryTerms[queryFields.appointmentDate] = date;
      }

      // set terms
      setQueryTerms(tempQueryTerms);

      // generate results
      if (tempQueryTerms[queryFields.latitude] && tempQueryTerms[queryFields.longitude]) {
        getResults(null, null, tempQueryTerms);
      }

      // trigger gestation calculation
      if (tempQueryTerms[queryFields.lastMenstrualPeriod] || tempQueryTerms[queryFields.appointmentDate]) {
        setCalculateGestation(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceOptions.length])

  useEffect(() => {
    const tempQueryTerms = {...queryTerms};
    if (lastMenstrualDate) { // not null
      tempQueryTerms[queryFields.lastMenstrualPeriod] = lastMenstrualDate;
    }
    else {
      tempQueryTerms[queryFields.lastMenstrualPeriod] = "";
    }
    setQueryTerms(tempQueryTerms);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMenstrualDate])

  useEffect(() => {
    const tempQueryTerms = {...queryTerms};
    if (appointmentDate) { // not null
      tempQueryTerms[queryFields.appointmentDate] = appointmentDate;
    }
    else {
      tempQueryTerms[queryFields.appointmentDate] = "";
    }
    setQueryTerms(tempQueryTerms);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentDate])

  const onResetGestation = () => {
    const tempQueryTerms = {
      ...queryTerms,
      [queryFields.lastMenstrualPeriod]: null,
      [queryFields.appointmentDate]: null,
    }
    setQueryTerms(tempQueryTerms);
  }

  // Sets the data when the user manually selects a suburb name from the second postcode dropdown in searchbar
  const manualSuburbCallback = (suburb, postcode, placeIdInput) => {
    setSuburbData({postcode, suburb})
    setError(false);
    setLocationQueryError("");
    setPlaceId(placeIdInput);
  }

  return (
    <Grid item xs={4}>
        <Paper square elevation={0} className={classes.pad} >
          <div className={classes.scroll}>
            <SearchOptionCard title="Location">
              <SearchBar token={googleToken.current} callback={callbackSearchBar} defaultValue={defaultAddress} reset={reset} manualSuburbCallback={manualSuburbCallback} />
            </SearchOptionCard>
            <AlternativeFundingPathways suburbData={suburbData} reset={reset} userHasSearched={results.length > 0}/>
            <SearchOptionCard title="Service">
              <ServiceFilter onSelect={onSelectService} options={serviceOptions}/>
            </SearchOptionCard>
            {showGestationCalculator && (
              <SearchOptionCard title="Gestation Calculator">
                <GestationCalculator
                  reset={reset}
                  lastMenstrualDate={lastMenstrualDate}
                  setlastMenstrualDate={setlastMenstrualDate}
                  appointmentDate={appointmentDate}
                  setAppointmentDate={setAppointmentDate}
                  onResetGestation={onResetGestation}
                  calculateGestation={calculateGestation}
                  setCalculateGestation={setCalculateGestation}
                />
              </SearchOptionCard>
            )}
            <SearchOptionCard title="Ultrasound Report">
              <UltrasoundReportCalculator reset={reset} />
            </SearchOptionCard>
            {hasError && <span> Error getting details. Try a different address.</span>}
            {locationQueryError && <div>{locationQueryError}</div>}
          </div>
          <SearchButton onSearch={onSearch} onReset={onReset} disabled={!(placeId || (queryTerms.lat && queryTerms.long))}/>
        </Paper>
    </Grid>
  );
}

LeftPanel.propTypes = {
  resultsCallback: PropTypes.func.isRequired,
  loadingCallback: PropTypes.func.isRequired
}
