import bent from 'bent';
import * as constants from '../resources/constants';
import { showErrorData } from './Toast';
import { isValidNhsdResponse } from './Utils';

const getJSON = bent('json');

// Makes a call to the API to get hospitals
// Inputs: Latitude, Longitude, Count
// Count is the number of results to return
// Output: JSON response with data and metadata
export default async (lat, lon, count) => {
    const url =
        `${process.env.REACT_APP_API_URL}${constants.API_VERSION}${constants.routes.hospitals.nearby}${count}?${constants.params.lat}${lat}&${constants.params.lon}${lon}`;

    const res = await getJSON(url);
    if (isValidNhsdResponse(res)) {
        res.data.forEach(hospital => {
            // eslint-disable-next-line no-param-reassign
            hospital.dataType = constants.locations.hospital;
        });
    } else {
        showErrorData(`${constants.failedGet} from ${url}`, res);
        res.data = [];
    }
    return res;
}