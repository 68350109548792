/* eslint-disable arrow-body-style */
import React, { useState } from "react";
import { Grid, TextField, makeStyles, Paper, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SearchOptionCard from "../components/SearchOptionCard/SearchOptionCard";
import { useLoginMutation } from "../redux/features/auth/authApi";
import { setCredentials } from "../redux/features/auth/authSlice";
import { showErrors } from "../scripts/Toast";

const useStyles = makeStyles({
  root: {
    paddingLeft: "0.75em",
    paddingRight: "0.75em",
  },
  marginTop: {
    marginTop: "1em",
  },
  pad: {
    padding: "0.5em",
  },
});

const AdminLogin = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, { isLoading }] = useLoginMutation();

  const handleLogin = async () => {
    try {
      const user = await login({ email, password }).unwrap();
      const { token } = user;
      dispatch(setCredentials({ email, token }));
      push("/admin/clinics");
    } catch (err) {
      showErrors(err.data);
    }
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item>
        <Paper square elevation={0} className={classes.pad}>
          <SearchOptionCard title="Admin Login">
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <TextField
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                  id="email-input"
                  label="Email"
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  onChange={(e) => setPassword(e.target.value)}
                  id="password-input"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={handleLogin}
                  disabled={isLoading}
                  variant="contained"
                  color="primary"
                  align="center"
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </SearchOptionCard>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AdminLogin;
