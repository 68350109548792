import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PaymentIcon from '@material-ui/icons/Payment';
import PhoneIcon from '@material-ui/icons/Phone';
import PrintIcon from '@material-ui/icons/Print';
import PropTypes from 'prop-types';
import { Avatar, Button, Chip, Grid, Tooltip } from "@material-ui/core";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import * as constants from '../../resources/constants';
import { getFaxNumber, getPhoneNumber } from '../../scripts/nhsdUtil';
import { getBillingTooltip } from '../../scripts/Utils';
import OpeningHours from '../OpeningHours/OpeningHours';
import LocationAlerts from "../LocationAlerts/LocationAlerts";
import Feedback from "../Feedback/Feedback";
import Contacts from "../Contacts/Contacts";
import { facilityLabels, facilityColours } from "../../resources/constants";

const useStyles = makeStyles({
  marginAndStretch: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0
  },
  avatar: {
    height: '3em',
    width: '3em',
    color: '#000000',
    textTransform: 'capitalize'
  },
  left: {
    textAlign: 'left',
  },
  shortLine: {
    marginBlockStart: 0,
    marginBlockEnd: 0
  },
  greyShortLine: {
    color: '#666666',
    marginBlockStart: 0,
    marginBlockEnd: 0
  },
  detailText: {
    marginBlockStart: 0,
  },
  bigText: {
    fontSize: '1em'
  },
  alignLeft: {
    textAlign: 'left'
  },
  justifyCenter: {
    justifyContent: 'center'
  },
  alert: {
    marginBottom: '0.4em'
  },
  chipRow: {
    width: '100%',
    textAlign: 'left'
  },
  staticChip: {
    borderRadius: '5px',
    marginBottom: '0.2em',
    marginTop: '0.2em',
    marginRight: '0.4em',
    textAlign: 'left',
    backgroundColor: '#f5f5f5'
  },
  grey: {
    color: '#5d5d5d',
  },
  oddRow: {
    '& .MuiAccordionSummary-root': {
      backgroundColor: '#e9e9e9'
    },
    '& .MuiAccordionDetails-root': {
      backgroundColor: '#e9e9e9'
    }
  }
});

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: 0,
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    paddingTop: 0,
    '& .MuiChip-root .MuiChip-avatarColorPrimary': {
      backgroundColor: 'transparent',
    },
  },
}))(MuiAccordionDetails);

function constructClinicAddress(data) {
  let string = data.add_line_1;
  if (data.add_line_2) string += `, ${data.add_line_2}`;
  if (data.add_line_3) string += `, ${data.add_line_3}`;
  return string;
}

// eslint-disable-next-line no-unused-vars
export default function LocationItem({ data, bgStyle }) {
  const classes = useStyles();
  const [avatarText, setAvatarText] = useState('');
  const [locationName, setLocationName] = useState('');
  const [addressPartStreet, setAddressPartStreet] = useState('');
  const [addressPartSuburb, setAddressPartSuburb] = useState('');
  const [addressPartPostcode, setAddressPartPostcode] = useState('');
  const [faxNumber, setFaxNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [bulkBillingStatus, setBulkBillingStatus] = useState({ label: '', tooltip: '' });
  const [distance, setDistance] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);
  const [isNhsd, setIsNhsd] = useState(false);
  const [colour, setColour] = useState();

  useEffect(() => {
    const isThisNhsd = data.location !== undefined;
    const isHospital = data.dataType === constants.locations.hospital;
    const addressStreet = isThisNhsd ? data.location.physicalLocation.addressLine3 : constructClinicAddress(data);
    const addressSuburb = isThisNhsd ? data.location.physicalLocation.suburb.label : data.suburb;
    const addressPostcode = isThisNhsd ? data.location.physicalLocation.postcode : data.postcode;
    const locationDistance = isThisNhsd ? (data.distanceFromOrigin/1000).toFixed(2) : (data.distance/1000).toFixed(2);
    const bulkBilling = isThisNhsd && !isHospital ?
    {
      label: data.billingOptions[0].valueType.label,
      tooltip: getBillingTooltip(data.billingOptions[0].valueType)
    } :
    {
      label: constants.dataNA,
      tooltip: getBillingTooltip()
    };
    const phone = isThisNhsd ? getPhoneNumber(data) : constants.dataNA;
    const fax = isThisNhsd ? getFaxNumber(data) : constants.dataNA;
    let { name } = data;
    if (name === null || name === undefined || name.trim().length === 0) {
      if (data.displayName == null || data.displayName.trim().length === 0) {
        name = data.organisation ? data.organisation.name : 'No specified name'
      } else {
        name = data.displayName;
      }
    }

    setIsNhsd(isThisNhsd);
    setLocationName(name)
    setAddressPartStreet(addressStreet);
    setAddressPartSuburb(addressSuburb);
    setAddressPartPostcode(addressPostcode);
    setDistance(locationDistance);
    setBulkBillingStatus(bulkBilling);
    setPhoneNumber(phone);
    setFaxNumber(fax);
    setAvatarText(facilityLabels[data.dataType].substr(0, 4));
    setColour(facilityColours[data.dataType]);
  }, [data]);

  const onFlag = () => {
      setShowFeedback(true)
  }

      return(
        <Accordion elevation={0} className={bgStyle === 0 ? classes.oddRow : ''}>
          <AccordionSummary>
            <Grid className={classes.marginAndStretch} container spacing={1}>
              <Grid item>
                <Avatar
                  aria-label="avatar"
                  className={classes.avatar}
                  style={{backgroundColor: colour}}
                >
                  {avatarText}
                </Avatar>
              </Grid>
              <Grid item xs={10} className={classes.left}>
                <p className={classes.shortLine}>{locationName}</p>
                <p className={classes.greyShortLine}>{addressPartStreet}, {addressPartSuburb},</p>
                <p className={classes.greyShortLine}>{addressPartPostcode}</p>
              </Grid>
              <Grid item xs={1}>
                <p className={classes.detailText}>Distance</p>
                <p className={classes.bigText}>{distance}km</p>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid container item xs={1} />
              <Grid container item xs={5} className={data.calendar ? '' :classes.justifyCenter}>
                <LocationAlerts data={data} distance={distance} dataType={data.dataType} />
                <div className={classes.grey}>
                  { data.calendar ? <OpeningHours data={data.calendar} /> : 'No opening hours available'}
                </div>
              </Grid>
              {!isNhsd &&
              <Grid container item xs={6}>
                <Contacts data={data}/>
              </Grid>
              }
              {!isNhsd && <Grid container item xs={1} />}
              <Grid container item xs={6}>
                {!isNhsd && data.services &&
                  <div className={classes.chipRow}>
                    <div className={classes.grey}> Available Services </div>
                    {data.services.map((service) =>
                      <Tooltip title={service.description} key={service.description}>
                        <Chip label={service.service_type} className={classes.staticChip}/>
                      </Tooltip>
                    )}
                  </div>
                }
              <div className={classes.chipRow} >
                  {isNhsd && <Chip icon={<PrintIcon />} label={faxNumber} className={classes.staticChip} /> }
                  {isNhsd && <Chip icon={<PhoneIcon />} label={phoneNumber} className={classes.staticChip} /> }
                  {isNhsd &&
                    <Tooltip title={bulkBillingStatus.tooltip}>
                      <Chip icon={<PaymentIcon />} label={bulkBillingStatus.label} className={classes.staticChip} />
                    </Tooltip>
                  }
                  {!showFeedback && !isNhsd &&
                    <Button onClick={onFlag} variant="outlined" color="primary" align="center">
                      Feedback
                    </Button>
                  }
                </div>
              </Grid>
              <Grid container item xs={12}>
                {!isNhsd && showFeedback && <Feedback context={`Clinic ${data.id}`} onCancel={() => { setShowFeedback(false) }}/>}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
  }

  LocationItem.propTypes = {
    data: PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      dataType: PropTypes.string.isRequired,
      'review_flag': PropTypes.number,
      name: PropTypes.string,
      displayName: PropTypes.string,
      suburb: PropTypes.string,
      street_address: PropTypes.string,
      postcode: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      distance: PropTypes.string,
      location: PropTypes.shape({
        name: PropTypes.string,
        physicalLocation: PropTypes.shape({
          addressLine3: PropTypes.string,
          suburb: PropTypes.shape({
            label: PropTypes.string
          }),
          postcode: PropTypes.string
        }),
      }),
      distanceFromOrigin: PropTypes.number,
      organisation: PropTypes.shape({
        name: PropTypes.string,
      }),
      billingOptions: PropTypes.arrayOf(PropTypes.shape()),
      calendar: PropTypes.shape(),
      services: PropTypes.arrayOf(PropTypes.shape())
    }).isRequired,
    bgStyle: PropTypes.number.isRequired
  }