import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { AppBar, Badge, Toolbar as MaterialToolbar, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Menu } from '@material-ui/icons';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { getOpenFeedback } from "../../scripts/FeedbackService";

const useStyles = makeStyles({
  center: {
    textAlign: 'center',
    fontSize: '1.5rem',
    flexGrow: 1
  },
  padRight: {
      paddingRight: '108px'
  },
  margin: {
      marginBottom: '1em'
  },
  grow: {
    flexGrow: 1,
  },
});

export default function Toolbar({openDrawer}) {
    const classes = useStyles();
    const history = useHistory();
    const [openFeedback, setOpenFeedback] = useState(0);

    // Get notification count, every 5 minutes.
    useEffect(() => {
      const getData = async () => {
        const res = await getOpenFeedback() ;
        setOpenFeedback(parseInt(res.data, 10));
      }

      const interval = setInterval(() => {
        getData();
      }, 5 * 60 * 1000);

      return () => clearInterval(interval);
    }, []);
    const goToFeedback = () => {
      history.push('/admin/feedback');
    }
      return(
        <div>
            <AppBar position="sticky" className={classes.margin}>
                <MaterialToolbar>
                    <span className={classes.padRight}>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={openDrawer}>
                            <Menu />
                        </IconButton>
                    </span>
                    <div className={classes.grow} />
                    <span className={classes.center}>
                        Marie Stopes Lookup Tool
                    </span>
                    <div className={classes.grow} />
                    {openFeedback > 0 &&
                    <IconButton onClick={goToFeedback} color="inherit">
                       <Badge badgeContent={openFeedback} color="secondary">
                        <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    }
                </MaterialToolbar>
          </AppBar>
        </div>
      );
  }


Toolbar.propTypes= {
    openDrawer: PropTypes.func.isRequired,
}