/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-boolean-value */
import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, ButtonGroup, CircularProgress, IconButton, makeStyles } from '@material-ui/core';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid'
import SaveIcon from '@material-ui/icons/Save';
import { createRow, getTable, updateTable, deleteRow } from '../../scripts/AdminService';

const useStyles = makeStyles({
    loadingIcon: {
        position: 'relative',
        top: '50%',
      },
      center: {
          justifyContent: 'center',
          backgroundColor: 'white',
          height: '100%'
      },
      margin: {
          margin: '0.3em'
      },
      root: {
        '& .MuiDataGrid-root, .MuiDataGrid-row--editing.MuiDataGrid-cell': {
            backgroundColor: '#c8e4fb',
        },
        '& .MuiDataGrid-root, .MuiDataGrid-row--editing': {
            backgroundColor: '#bbdefb',
        },
        '& .MuiDataGrid-root, .MuiDataGrid-columnHeader': {
            backgroundColor: '#5990f7',
            color: 'white'
        },
        '& .MuiDataGrid-root, .MuiDataGrid-columnHeaderWrapper .MuiDataGrid-cell': {
            backgroundColor: '#5990f7',
        },
        '& .MuiDataGrid-root, .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-root .MuiDataGrid-cellCheckbox': {
            height: '100%'
        }
      }
})

// The datagrid returns a model where the id of the row is the key and an object holding the
// other values is the value. This moves all of those values to the same level as the id
// so that the value can be sent to the API and parsed properly
function fixEditModel(model) {
    const newObj = {}
    Object.keys(model).forEach((key) => {
        newObj[key] = model[key].value;
    });
    return newObj;
}

// A functional component for the table's toolbar
// Input parameters are callbacks for buttons and state to show the confirmation buttons
function ToolbarComponent({ handleAddRow, handleDeleteRow, shouldShowConfirmDelete, handleConfirmDelete }) {
    const classes = useStyles();

    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton variant="contained" />
            <ButtonGroup className={classes.margin} size="small" color="primary" variant="contained" aria-label="Add/Remove button">
                <Button onClick={handleAddRow}>Add row</Button>
                <Button onClick={handleDeleteRow}>Delete row</Button>
            </ButtonGroup>

            {shouldShowConfirmDelete &&
            <ButtonGroup className={classes.margin} size="small" color="secondary" variant="contained" aria-label="Add/Remove button">
                <Button onClick={() => handleConfirmDelete(true)}>Confirm</Button>
                <Button onClick={() => handleConfirmDelete(false)}>Cancel</Button>
            </ButtonGroup>
            }
        </GridToolbarContainer>
    )
}

ToolbarComponent.propTypes = {
    handleAddRow: PropTypes.func.isRequired,
    handleDeleteRow: PropTypes.func.isRequired,
    shouldShowConfirmDelete: PropTypes.bool.isRequired,
    handleConfirmDelete: PropTypes.func.isRequired,
}

export default function AdminTable({tableName}) {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [shouldShowConfirmDelete, setsShouldShowConfirmDelete] = useState(false);
    const [columns, setColumns] = useState([]);
    const [editRowsModel, setEditRowsModel] = useState({});
    const [selectionModel, setSelectionModel] = useState();
    const [shouldUpdateTable, setShouldUpdateTable] = useState({});
    const [page, setPage] = useState(0);

    const [modifiedRows, setModifiedRows] = useState([]);
    const modRef = useRef();

    modRef.current = modifiedRows;

    // Save button callback.
    // If this is a new row then we use the createRow method instead of updating ar ow
    const handleSave = (x) => {
        modRef.current.some(async (row) => {
            // Our table has the value as a string, but it's actually an int, so we just want ==, not ===
            if (row.id == x) {
                setLoading(true);
                const res = x.toString().includes('NEW') ? await createRow(tableName, row) : await updateTable(tableName, row)
                if (res === null) {
                    setLoading(false)
                } else {
                    setShouldUpdateTable(!shouldUpdateTable)
                }
                return true;
            }
            return false;
        });
        setModifiedRows([])
        modRef.current = [];
    }

    // Store the value of rows after they're edited
    const handleEditRowsModelChange = (model) => {
        setEditRowsModel(model);
      }

    // helper method to check if a given row has been modified
    const hasBeenModified = (id) => {
        let res = false;
        modRef.current.forEach(row => {
            // as above - id in table is rendered as a string, but actually is an int
            if (row.id == id) res = true;
        })
        return res;
    }

    // Adds a new blank row with the id starting with NEW when we click add row
    const handleAddRow = () => {
        const newId = _.uniqueId('NEW');
        setPage(0);
        setRows([{id: newId}, ...rows]);
    }

    // Shows the confirmation dialog on clicking delete
    const handleDeleteRow = () => {
        setsShouldShowConfirmDelete(true)
    }

    // Has the actual delete logic for when confirm is clicked, otherwise hides the dialog
    const handleConfirmDelete = async (shouldDelete) => {
        setsShouldShowConfirmDelete(false);
        if (shouldDelete) {
            setLoading(true);
            const selectedRowId = selectionModel[0];
            const selectedRow = rows.filter(row => row.id == selectedRowId)[0]
            await deleteRow(tableName, selectedRow)
            setShouldUpdateTable(!shouldUpdateTable)
        }
        setSelectionModel([]);
    }

    // Reloads component based on state of loading
    React.useEffect(() => {}, [isLoading]);

    // Gets the table data from the API when we change table or force an update with shouldUpdateTable
    useEffect(() => {
        setLoading(true);
        async function getThisTable() {
            const { tableRows, tableColumns } = await getTable(tableName);
            setRows(tableRows);
            tableColumns[0].renderCell = (params) => (
                    <div>
                        {hasBeenModified(params.value) &&
                        <IconButton color="primary" onClick={() => {handleSave(params.value)}}>
                            <SaveIcon />
                        </IconButton>
                        }

                        {params.value}
                    </div>);
            setModifiedRows([]);
            setColumns(tableColumns);
            setLoading(false)
        }

        getThisTable();
        // We actually *dont* want handleSave to trigger this
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableName, shouldUpdateTable]);

    // Makes the save button conditionally render
    React.useEffect(() => {}, [modifiedRows]);

    // Controls the state of which rows have been modified
    const handleRowEditCommit = () => {
        const currentEditModel = {...editRowsModel};
        const id = Object.keys(currentEditModel)[0];
        const otherColumns = fixEditModel(currentEditModel[id]);
        const newRowObj = {
            id,
            ...otherColumns
        }
        setModifiedRows(existingRows => [...existingRows, newRowObj])
    }

    return(
        <Box display="flex" className={classes.center}>
            {
                isLoading ?
                <CircularProgress className={classes.loadingIcon} size="7em" /> :
                <DataGrid
                    autoHeight
                    className={classes.root}
                    rows={rows}
                    columns={columns}
                    pageSize={15}
                    rowsPerPageOptions={[15]}
                    pagination
                    page={page}
                    onPageChange={(newPage) => setPage(newPage)}
                    editMode="row"
                    editRowsModel={editRowsModel}
                    onEditRowsModelChange={handleEditRowsModelChange}
                    selectionModel={selectionModel}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                      }}
                    onRowEditStop={handleRowEditCommit}
                    components={{
                        Toolbar: ToolbarComponent,
                      }}
                    componentsProps={{
                        toolbar: { handleAddRow, handleDeleteRow, handleConfirmDelete, shouldShowConfirmDelete },
                    }}
                    isCellEditable={(params) =>
                        !params.row.id.toString().includes('clinics_services')
                    }
                    />
            }
        </Box>
    );
}

AdminTable.propTypes = {
    tableName: PropTypes.string.isRequired
}