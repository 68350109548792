import React from 'react';
import { toast } from 'react-toastify';

const baseToast = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export function showInfo(msg) {
    toast.info(msg, baseToast)
}

export function showWarning(msg) {
    toast.warning(msg, baseToast)
}

export function showError(msg) {
    toast.error(msg, baseToast)
}

export function showErrorData(msg, data) {
    if(data.meta.status.errors) {
        data.meta.status.errors.forEach(error => {
            showError(
                <div>
                    {msg} <br />
                    {error.type} | {error.code}: <br />
                    {error.message} <br />
                    {error.developerMessage}
                </div>
            )
        })
    } else {
        showError(msg)
    }
}

function showSuccess(msg) {
    toast.success(msg, baseToast)
}

export async function showSuccessRes(res) {
    try {
        const body = await res.json();
        showSuccess(body.message);
    } catch {
        // This only happens if there's no response body, it's not a real error per se, so we can just show this:
        showSuccess(res.statusText);
    }

}

export function showErrors(responseBody) {
    responseBody.errors.forEach(error => {
        showError(
            <div>
                {error.msg} <br />
                Value: {error.value}
            </div>
        );
    });
}