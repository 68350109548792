import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, makeStyles } from "@material-ui/core";
import LocationItem from '../LocationItem/LocationItem';
import FacilityTypeFilter from '../FacilityTypeFilter/FacilityTypeFilter';

const useStyles = makeStyles({
  root: {
  },
  paper: {
    padding: '0.5em',
  },
  results: {
    overflowY: "auto",
    height: "calc(100vh - 150px)",
  },
  scroll: {
    overflowY: "scroll",
  }
});

/**
 * Displays clinic/hospital/etc. results with filter
 * @param {Object} results
 */
export default function RightPanel({ results }) {
  const classes = useStyles();
  const [filterValues, setFilterValues] = useState([]);

  return (
    <Grid item xs={8} className={classes.root}>
      <Paper square elevation={0} className={classes.paper} >
        <FacilityTypeFilter setFilterValues={setFilterValues}/>
        <div className={classes.results}>
          { results &&
            <div className={classes.results}>
              {results.filter(result => filterValues.includes(result.dataType)).map((result, index) =>
                <LocationItem key={result.id} data={result} bgStyle={index % 2} />
              )}
            </div>
          }
        </div>
      </Paper>
    </Grid>
  );
}

RightPanel.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape()).isRequired
}