import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import marieStopesApi from './mariestopesApi';

const store = configureStore({
  reducer: {
    auth: authReducer,
    [marieStopesApi.reducerPath]: marieStopesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(marieStopesApi.middleware),
});

export default store;
