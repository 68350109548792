import { convertToDateObject } from '../../scripts/Utils';

// eslint-disable-next-line import/prefer-default-export
export const feedbackSchema = {
    columns: [
    {
        field: 'id',
        headerName: 'ID',
        width: 90
    },
    {
        field: 'note',
        headerName: 'Note',
        width: 300,
        editable: true,
    },
    {
        field: 'date_submitted',
        headerName: 'Submission Date',
        width: 250,
        editable: true,
        type: 'dateTime',
        valueGetter: (params) => convertToDateObject(params.value),
    },
    {
        field: 'date_completed',
        headerName: 'Completion Date',
        width: 250,
        editable: true,
        type: 'dateTime',
        valueGetter: (params) => convertToDateObject(params.value),
    },
    {
        field: 'submitted_by',
        headerName: 'Submitted By',
        width: 200,
        editable: true,
    },
    {
        field: 'actioned_by',
        headerName: 'Actioned by',
        width: 200,
        editable: true,
    },
    ]
};
