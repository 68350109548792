import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import getLinks from '../../scripts/LinksService';

const useStyles = makeStyles({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    // necessary for content to be below app bar
    justifyContent: 'flex-end',
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
});

function GetNavLink() {
  if (window.location.pathname.includes('admin')) {
    return { name: 'Back to lookup', path: '/' };
  }
  return { name: 'Admin', path: '/adminLogin' };
}

export default function LinksDrawer({
  closeDrawer,
  drawerState,
  showFeedback,
}) {
  const [links, setLinks] = useState([]);
  const [navLink, setNavLink] = useState({ name: '', path: '' });
  const classes = useStyles();
  const history = useHistory();

  const navigateTo = (path) => {
    history.push(path);
    setNavLink(GetNavLink());
    closeDrawer();
  };

  useEffect(() => {
    async function fetchLinks() {
      const retrievedLinks = await getLinks();
      setLinks(retrievedLinks.data);
    }
    if (links.length === 0) fetchLinks();
    setNavLink(GetNavLink());
  }, [links.length]);

  return (
    <Drawer
      variant='persistent'
      anchor='left'
      open={drawerState}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={closeDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem button onClick={showFeedback}>
          <ListItemText primary='Give feedback' />
        </ListItem>
      </List>
      <Divider />
      {links.length > 0 && (
        <List>
          {links.map((link) => (
            <ListItem
              button
              key={link.name}
              component={Link}
              target='_blank'
              href={link.url}
            >
              <ListItemText primary={link.name} />
            </ListItem>
          ))}
          <Divider />
        </List>
      )}
      <List>
        <ListItem
          button
          key={navLink.name}
          to={navLink.path}
          onClick={() => {
            navigateTo(navLink.path);
          }}
        >
          <ListItemText primary={navLink.name} />
        </ListItem>
      </List>
    </Drawer>
  );
}

LinksDrawer.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  drawerState: PropTypes.bool.isRequired,
  showFeedback: PropTypes.func.isRequired,
};
