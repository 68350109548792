import React, { useEffect, useMemo, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { IconButton, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import HomeIcon from '@material-ui/icons/Home';
import Autocomplete from '@material-ui/lab/Autocomplete';
import throttle from 'lodash/throttle';
import parse from 'autosuggest-highlight/parse';
import GeocodeService from '../../scripts/GeocodeService';
import { showError } from "../../scripts/Toast";

const useStyles = makeStyles({
  selectedButton: {
    backgroundColor: '#5990f7',
    color: 'white',
    '&:hover': {
      backgroundColor: '#5990f7',
      color: 'white',
    }
  },
  unselectedButton: {
    backgroundColor: 'white',
    boxShadow: 'inset 0px 0px 0px 1px rgba(0,0,0,0.5)',
    '&:hover': {
      boxShadow: 'inset 0px 0px 0px 1px #78909C',
    }
  },
  buttonLabel: {
    fontSize: '0.75em',
    textAlign: 'center',
    color: 'rgba(0,0,0,0.87)'
  },
  iconGrid: {
    display: 'table',
    marginRight: '0.25em',
    marginLeft: '0.25em',
  },
  padSearch: {
    paddingLeft: '0.25em'
  },
  autocomplete: {
    flexGrow: "1",
    maxWidth: "none",
    marginRight: '0.25em',
    marginLeft: '0.25em',
  }
});

export default function SearchBar({ token, callback, defaultValue, manualSuburbCallback, reset }) {
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [usePostcode, setUsePostcode] = useState(true);
  const [label, setLabel] = useState('');
  const [isVerifyingPostcode, setIsVerifyingPostcode] = useState(false);
  const [open, setOpen] = useState(false);
  const [querySuburbPostcode, setQuerySuburbPostcode] = useState('');
  const verifyingPostcodeSecondaryTextRef = useRef();
  const searchPlaceId = useRef();

  const fetch = useMemo(
    () =>
      throttle((request, cb) => {
        const types = usePostcode ? ['(regions)'] : ["geocode"];
        new window.google.maps.places.AutocompleteService().getPlacePredictions(
          {
              types,
              sessionToken: token,
              input: inputValue,
              componentRestrictions: {
                  country: "au"
              },
        }, cb)
      }, 200),
    [inputValue, token, usePostcode],
  );

  // Used to handle the reset button
  useEffect(() => {
    setInputValue('')
  }, [reset])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return;
    }

    if (!isVerifyingPostcode) {
      fetch({ input: inputValue, sessionToken: token }, (results) => {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      });
    }
  }, [value, inputValue, fetch, token, isVerifyingPostcode]);

  // handle rendering defaultvalue for label
  useEffect(() => {
    if(defaultValue) {
      setInputValue(defaultValue);
      setUsePostcode(false);
    }
  }, [defaultValue]);

  useEffect(() => {
    setLabel(usePostcode ? "Postcode" : "Address")
  }, [usePostcode])

  const handleLocation = (placeId) => {
    callback(placeId);
  }

  const handleClickSuburb = () => {
    setUsePostcode(true);
  };

  const handleClickAddress = () => {
    setUsePostcode(false);
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const handleNoGeocodeResults = () => {
    callback(searchPlaceId.current);
  }

  const geocodeCallback = (results, status) => {
    switch(status) {
      case('ZERO_RESULTS'):
        handleNoGeocodeResults();
        return;
      case('OK'):
        break;
      default:
        showError('Failed to access google API');
        return;
    }

    const possibleSuburbs = results[0].postcode_localities;
    if (!possibleSuburbs || possibleSuburbs.length === 1) {
      handleNoGeocodeResults();
      return;
    }

    setIsVerifyingPostcode(true)
    const newOptions = [];
    possibleSuburbs.forEach(suburb => {
      newOptions.push({
        structured_formatting: {
          main_text: suburb,
          main_text_matched_substrings: [],
          secondary_text: verifyingPostcodeSecondaryTextRef.current
        },
        description: suburb
      })
    });
    setOptions(newOptions);
    setOpen(true);
  }

  const handleAutoCompleteChange = (newValue) => {
    setOpen(false);
    setValue(newValue);
    // If we're selecting a postcode from the second dropdown
    if (isVerifyingPostcode && newValue) {
      const suburbName = newValue.description;
      setInputValue(`${suburbName} ${verifyingPostcodeSecondaryTextRef.current}`);
      manualSuburbCallback(suburbName, querySuburbPostcode, searchPlaceId.current);
    // If we're selecting a postcode from the first dropdown
    } else if (usePostcode && newValue && newValue.structured_formatting) {
      setIsVerifyingPostcode(true);
      const postcode = newValue.structured_formatting.main_text;
      if (newValue.description) {
        const addressDescriptionParts = newValue.description.split(' ');
        const {length} = addressDescriptionParts;
        verifyingPostcodeSecondaryTextRef.current = `${addressDescriptionParts[length-3]} ${addressDescriptionParts[length-2]} ${addressDescriptionParts[length-1]}`;
      }
      if (newValue.terms) setQuerySuburbPostcode(postcode);
      searchPlaceId.current = newValue.place_id;
      GeocodeService(postcode, geocodeCallback)
    // Handle like normal if we're using the address search
    } else if  (!isVerifyingPostcode && newValue && newValue.place_id !== null) {
      handleLocation(newValue.place_id);
    }
  }

  return (
    <Grid container>
      <Grid item xs={1} className={classes.iconGrid}>
        <IconButton
          aria-label="address button"
          onClick={handleClickAddress}
          onMouseDown={handleMouseDown}
          className={usePostcode ? classes.unselectedButton : classes.selectedButton}
          disableRipple
        >
          <HomeIcon />
        </IconButton>
        <span className={classes.buttonLabel}>Address</span>
      </Grid>
      <Grid item xs={1} className={classes.iconGrid}>
        <IconButton
          aria-label="postcode button"
          onClick={handleClickSuburb}
          onMouseDown={handleMouseDown}
          className={usePostcode ? classes.selectedButton : classes.unselectedButton}
          disableRipple
        >
          <LocationCityIcon />
        </IconButton>
        <span className={classes.buttonLabel}>Postcode</span>
      </Grid>
      <Grid item xs={6} className={classes.autocomplete}>
        <Autocomplete
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          autoHighlight
          id="searchBar"
          options={options}
          getOptionLabel={(option) => option.description}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            if (event && event.type !=='blur') {
              setInputValue(newInputValue);
              if(event.type === 'change') {
                setQuerySuburbPostcode('');
                setIsVerifyingPostcode(false);
              }
            }
          }}
          onChange={(e, newValue)=> {
            handleAutoCompleteChange(newValue);
          }}
          getOptionSelected={(option, acValue) =>  isVerifyingPostcode || option === acValue}
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
          renderOption={(option) => {
            const matches = option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match) => [match.offset, match.offset + match.length]),
            );

            return (
              <Grid container alignItems="center">
                <Grid item xs>
                  {parts.map((part) => (
                    <span key={part.text} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}

                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
          />
      </Grid>
    </Grid>
  )
}

SearchBar.propTypes = {
  token: PropTypes.shape(),
  callback: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  manualSuburbCallback: PropTypes.func.isRequired,
  reset: PropTypes.bool.isRequired
}

// The searchbar initially loads before we can get a token from google
SearchBar.defaultProps = {
  token: null,
  defaultValue: null,
};