import React, { useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import _ from 'lodash';
import PropTypes from 'prop-types';
import convertTime from '../../scripts/Utils';

const useStyles = makeStyles({
   center: {
       textAlign: 'center',
   },
   right: {
       textAlign: 'right',
   },
   textLeft: {
       textAlign: 'left',
   },
   alert: {
       marginTop: '1em',
   }
});

  /**
   * Takes an array of openRules and a day and sets the open and close times
   * @param {Array} openrules: array of openRules from NHSD api
   * @param {string} dayName: string containing the day name to search for in the rules
   * @param {function} dayCallback: callback function to set state
   */
function setHours(openRules, dayName, dayCallback) {
    let relevantRule;
    openRules.forEach(rule => {
        if (relevantRule == null && rule.days && rule.days.includes(dayName.toUpperCase())) {
            relevantRule = rule;
        }
    })
    if (!relevantRule) return;
    dayCallback({
        open: convertTime(relevantRule.openFrom),
        close: convertTime(relevantRule.openTo)
    })
}

  /**
   * Takes a variable and returns that variable's name as a string
   */
const varToString = varObj => Object.keys(varObj)[0];

export default function OpeningHours({ data }) {
    const [monday, setMonday] = useState({ open: '', close: ''});
    const [tuesday, setTuesday] = useState({ open: '', close: ''});
    const [wednesday, setWednesday] = useState({ open: '', close: ''});
    const [thursday, setThursday] = useState({ open: '', close: ''});
    const [friday, setFriday] = useState({ open: '', close: ''});
    const [saturday, setSaturday] = useState({ open: '', close: ''});
    const [sunday, setSunday] = useState({ open: '', close: ''});

    const classes = useStyles();
    const days = [ monday, tuesday, wednesday, thursday, friday, saturday, sunday]

    useEffect(() => {
        setHours(data.openRule, varToString({monday}), setMonday);
        setHours(data.openRule, varToString({tuesday}), setTuesday);
        setHours(data.openRule, varToString({wednesday}), setWednesday);
        setHours(data.openRule, varToString({thursday}), setThursday);
        setHours(data.openRule, varToString({friday}), setFriday);
        setHours(data.openRule, varToString({saturday}), setSaturday);
        setHours(data.openRule, varToString({sunday}), setSunday);
    // Using this here because adding these as depencies causes an infinite loop.
    // We only want this to run on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openTimes = days.map(day => {
        const text = day.open && day.close ? day.open : 'Closed';
        return <span key={_.uniqueId('open')}>{text} <br /> </span>
    });

    const closeTimes = days.map(day => <span key={_.uniqueId('close')}>{day.close} <br /> </span>);

    const dividers = days.map(day => {
        const text = day.open && day.close ? '-' : '';
        return <span key={_.uniqueId('divider')}>{text} <br /></span>
    });

    return(
        <div className={classes.textLeft}>
            Regular hours:
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    Monday:<br />
                    Tuesday:<br />
                    Wednesday:<br />
                    Thursday:<br />
                    Friday:<br />
                    Saturday:<br />
                    Sunday:<br />
                </Grid>
                <Grid item xs={3}>
                    {openTimes}
                </Grid>
                <Grid className={classes.center} item xs={1}>
                    {dividers}
                </Grid>
                <Grid className={classes.right} item xs={2}>
                    {closeTimes}
                </Grid>
            </Grid>
        </div>
    );
}
OpeningHours.propTypes = {
    data: PropTypes.shape({
        openRule: PropTypes.arrayOf(PropTypes.shape({
            pattern: PropTypes.string,
            days: PropTypes.arrayOf(PropTypes.string),
            openFrom: PropTypes.string,
            openTo: PropTypes.string,
            referenceDate: PropTypes.string,
        })),
        closedRule: PropTypes.arrayOf(PropTypes.shape({
            closedDate: PropTypes.string,
            reason: PropTypes.shape({
                valueType: PropTypes.shape({
                    idRef: PropTypes.string,
                    label: PropTypes.string,
                })
            }),
        })),
        timezone: PropTypes.string,
        alwaysOpen: PropTypes.bool
    })
};

// Data is not always available from NHSD
OpeningHours.defaultProps = {
    data: {}
}