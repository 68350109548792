import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Info } from "@material-ui/icons";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import getFundingPathways from '../../scripts/AlternativeFundingPathwaysService';

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.1)
    }
  },
  card: {
    marginTop: "-1rem",
    marginBottom: "1rem",
    position: "relative",
  },
  cardContent: {
    paddingTop: "0.1rem",
    paddingBottom: "0.1rem",
  },
  typography: {
    width: "fit-content",
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default function AlternativeFundingPathways ({ suburbData, reset, userHasSearched }) {
  const classes = useStyles();
  const [scriptingMessages, setScriptingMessages] = useState([]);
  const [eligibilityMessage, setEligibilityMessage] = useState("");

  // Reset button
  useEffect(() => {
    setScriptingMessages([]);
    setEligibilityMessage("");
  }, [reset])

  // In the case where this postcode doesn't have a related FP
  const setDefaults = () => {
    setEligibilityMessage("is not");
    setScriptingMessages(null);
  }

  const getScriptingMessages = async () => {
    if (suburbData == null) {
      setDefaults();
      return;
  }

    const fundingPathways = await getFundingPathways(suburbData.postcode);

    if (!fundingPathways || !fundingPathways.data || fundingPathways.data.length === 0) {
      setDefaults();
      return;
    }

    setEligibilityMessage("is");
    const searchedSuburb = suburbData.suburb.toLowerCase().trim();

    const messages = []
    fundingPathways.data.forEach((fundingPathway) => {
      const stateId = fundingPathway.state_id;
      if (fundingPathway.suburb.toLowerCase().trim() === searchedSuburb) {
        switch(stateId) {
          case(3):
            messages.push(["Under 15 weeks: ", fundingPathway.u15_weeks, "Over 15 weeks: ", fundingPathway.o15_weeks, "Note: ", fundingPathway.note]);
            break;
          case(4):
            messages.push(["Note: ", fundingPathway.note]);
            break;
          default:
            messages.push([""]);
          }
        }
      })

      setScriptingMessages(messages);
    }

  useEffect(() => {
    getScriptingMessages();
  // Causes an infinite loop
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suburbData])

  return (
  <>
    { userHasSearched && eligibilityMessage !== "" && suburbData &&
      <div className={classes.root}>
            <Card className={classes.card} variant="outlined">
              <CardContent className={classes.cardContent} style={{paddingBottom: "0.1rem"}}>
                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                  <Grid item xs={1}>
                    <Info/>
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="caption" align="left" style={{fontStyle: 'italic', fontSize: 12}}>
                      This client <b>{eligibilityMessage}</b> eligible for alternative funding pathways.
                    </Typography>
                  </Grid>
                </Grid>
                {scriptingMessages && scriptingMessages.map(messageBank =>
                  <Typography variant="body2" style={{fontSize: 12}} align = "justify">
                    <b>{messageBank[0]}</b> {messageBank[1]} <br/>
                    <b>{messageBank[2]}</b> {messageBank[3]} <br/>
                    <b>{messageBank[4]}</b> {messageBank[5]}
                  </Typography>
                )}
              </CardContent>
        </Card>
      </div>
    }
  </>
  );
}

AlternativeFundingPathways.propTypes= {
  suburbData: PropTypes.shape(),
  reset: PropTypes.bool.isRequired,
  userHasSearched: PropTypes.bool.isRequired,
}

AlternativeFundingPathways.defaultProps = {
  suburbData: null,
};