import bent from 'bent';
import { handleError } from './Utils';
import * as constants from '../resources/constants';


const getJSON = bent('json');

export default async (postcode) => {
  const url = `${process.env.REACT_APP_API_URL}${constants.API_VERSION}${constants.routes.funding_pathways.all}${constants.routes.scripting_messages.all}${postcode}`;
  try {
    const res = await getJSON(url);
    if (!res.data || res.data.length === 0) {
      res.data = [];
    }

    return res;
  } catch (e) {
    if (e.statusCode !== 404) await handleError(e);
    return null;
  }
}