import './App.css';
import React, { useEffect } from 'react';
import { Card, makeStyles } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import { Switch, Route } from 'react-router-dom';
import Toolbar from './components/toolbar/Toolbar';
import Admin from './views/Admin';
import Lookup from './views/Lookup';
import LinksDrawer from './components/LinksDrawer/LinksDrawer';
import 'react-toastify/dist/ReactToastify.css';
import Feedback from './components/Feedback/Feedback';
import PrivateRoute from './redux/features/auth/PrivateRoute';
import AdminLogin from './views/AdminLogin';

const url = `//maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_KEY}&language=en&libraries=places,geocoding`;

const useStyles = makeStyles({
  Feedback: {
    padding: '0.5em',
    marginBottom: '1em',
    marginRight: '0.5em',
    marginLeft: '0.5em',
  },
});

function loadScript() {
  const index = window.document.getElementsByTagName('script')[0];
  const script = window.document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

function App() {
  const [isInitialLoad, setIsInitialLoad] = React.useState(true);
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const [isFeedbackOpen, setFeedbackOpen] = React.useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (isInitialLoad) {
      loadScript();
      setIsInitialLoad(false);
    }
  }, [isInitialLoad]);

  const handleDrawer = (shouldShow) => {
    setDrawerOpen(shouldShow);
  };

  const handleFeedback = (shouldShow) => {
    if (shouldShow) setDrawerOpen(false);
    setFeedbackOpen(shouldShow);
  };

  return (
    <div className='App'>
      <ToastContainer />
      <Toolbar
        openDrawer={() => handleDrawer(true)}
        drawerState={isDrawerOpen}
      />
      {isFeedbackOpen && (
        <Card className={classes.Feedback}>
          <Feedback context='App' onCancel={() => handleFeedback(false)} />
        </Card>
      )}
      <LinksDrawer
        closeDrawer={() => handleDrawer(false)}
        drawerState={isDrawerOpen}
        showFeedback={() => {
          handleFeedback(true);
        }}
      />
      <Switch>
        <PrivateRoute path='/admin'>
          <Admin />
        </PrivateRoute>
        <Route path='/adminLogin'>
          <AdminLogin />
        </Route>
        <Route exact path='/'>
          <Lookup />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
